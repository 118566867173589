import "./styles.scss";

import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

import { IForgotFormType, ILoginFormType } from "./interfaces";
import { getForgotPassword, postLoginRequest } from "./api";
import {
  cookiesNames,
  localStorageNames,
  pageRoutes,
  UserContext,
} from "utils";
import { HomePageNavBar } from "components";

import loginSeparator from "assets/images/loginSeparator.png";
import logo from "assets/images/logoBig.png";
import { IContextUser } from "common/interfaces";

const Login = () => {
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);
  const user: IContextUser = useContext(UserContext)!;
  const { survey } = user;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormType>();

  const {
    register: registerForgotField,
    handleSubmit: handleSubmitForgot,
    formState: { errors: forgotErrors },
  } = useForm<IForgotFormType>();

  useEffect(() => {
    const userAlreadyCheckedRememberMe = localStorage.getItem(
      localStorageNames.rememberMe
    );
    if (userAlreadyCheckedRememberMe) {
      setRememberMeChecked(true);
    }

    if (Cookies.get(cookiesNames.token)) {
      if (survey?.completed) {
        window.location.href = pageRoutes.PROFITABLE_PRODUCTS;
      } else {
        window.location.href = pageRoutes.SURVEY_STEP1;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (Object.keys(errors) as (keyof typeof errors)[]).forEach((key, index) => {
      toast.error(errors[key]?.message);
    });
  }, [errors]);

  useEffect(() => {
    (Object.keys(forgotErrors) as (keyof typeof forgotErrors)[]).forEach(
      (key, index) => {
        toast.error(forgotErrors[key]?.message);
      }
    );
  }, [forgotErrors]);

  const { mutate: forgotPasswordMutate, isLoading: isLoadingForgotPassword } =
    useMutation(getForgotPassword, {
      onSuccess: (data) => {
        if (data.status === "success") {
          toast.success(data.message);
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message ??
          "There was an error reseting your password."
        );
      },
    });

  const { mutate, isLoading } = useMutation(postLoginRequest, {
    onSuccess: (data) => {
      let user = data;
      if (rememberMeChecked) {
        Cookies.set(cookiesNames.token, data.token, { expires: 1 });
      } else {
        Cookies.set(cookiesNames.token, data.token, { expires: 30 });
      }
      if (!user.survey) {
        user = {
          ...user,
          survey: {
            step1: [],
            step2: "",
            completed: false,
          },
        };
      }
      localStorage.setItem(localStorageNames.userObj, JSON.stringify(data));
      setTimeout(() => {
        if (data.survey?.completed) {
          window.location.href = pageRoutes.PROFITABLE_PRODUCTS;
        } else {
          window.location.href = pageRoutes.SURVEY_STEP1;
        }
      }, 500);
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ?? "There was an error signing you in."
      );
    },
  });

  const onSubmit = ({ email, password }: ILoginFormType) => {
    mutate({ email, password });
  };

  const onForgotSubmit = ({ forgotEmail }: IForgotFormType) => {
    forgotPasswordMutate({ forgotEmail });
  };

  const onRememberMeClick = () => {
    setRememberMeChecked(!rememberMeChecked);

    localStorage.setItem(
      localStorageNames.rememberMe,
      JSON.stringify(!rememberMeChecked)
    );
  };

  return (
    // <div className="container-fluid login-form-container">
    //   <div className="row">
    //     {!forgotPasswordActive && (
    //       <form
    //         autoComplete="off"
    //         className="login-form text-center"
    //         onSubmit={handleSubmit(onSubmit)}
    //       >
    //         <div className="login-form-logo text-center">
    //           <Link to="/">
    //             <img src={logo} alt="asinmice logo" />
    //           </Link>
    //         </div>
    //         <div
    //           className={`form-group mb-5 ${
    //             errors.email && "login-form-error"
    //           }`}
    //         >
    //           <input
    //             type="email"
    //             placeholder="Email"
    //             className="form-control login-form-input email-input"
    //             aria-describedby="emailHelp"
    //             {...register("email", {
    //               required: "Email field cannot be empty",
    //             })}
    //           />
    //           <span className="input-icon icon-email"></span>
    //         </div>
    //         <div
    //           className={`form-group mb-3 ${
    //             errors.password && "login-form-error"
    //           }`}
    //         >
    //           <input
    //             type="password"
    //             placeholder="Password"
    //             className="form-control login-form-input password-input"
    //             {...register("password", {
    //               required: "Password field cannot be empty",
    //             })}
    //           />
    //           <span className="input-icon icon-password"></span>
    //         </div>
    //         <div className="form-check login-form-remember-me">
    //           <div className="login-form-remember-me-group text-start">
    //             <input
    //               type="checkbox"
    //               className="form-check-input login-form-checkbox"
    //               id="login-form-checkbox"
    //               onChange={() => onRememberMeClick()}
    //               checked={rememberMeChecked}
    //             />
    //             <label
    //               className="form-check-label login-form-checkbox-label"
    //               htmlFor="login-form-checkbox"
    //             >
    //               Remember my username
    //             </label>
    //           </div>
    //         </div>
    //         <div className="form-group my-5">
    //           <button type="submit" className="btn login-form-submit-button">
    //             Login
    //           </button>
    //           {isLoading && (
    //             <div
    //               className="spinner-border login-form-submit-loading"
    //               role="status"
    //             >
    //               <span className="visually-hidden">Loading...</span>
    //             </div>
    //           )}
    //         </div>
    //         <div className="form-group login-form-separator mt-3">
    //           <img src={loginSeparator} alt="login separator" />
    //         </div>
    //         <div className="form-group login-form-forgot-password-link mt-3">
    //           <p onClick={() => setForgotPasswordActive(true)}>
    //             Forgot Password?
    //           </p>
    //         </div>
    //       </form>
    //     )}

    //     {forgotPasswordActive && (
    //       <form
    //         autoComplete="off"
    //         className="login-form"
    //         onSubmit={handleSubmitForgot(onForgotSubmit)}
    //       >
    //         <div className="login-form-logo text-center">
    //           <Link to="/">
    //             <img src={logo} alt="asinmice logo" />
    //           </Link>
    //         </div>
    //         <div
    //           className={`form-group mb-5 ${
    //             forgotErrors.forgotEmail && "login-form-error"
    //           }`}
    //         >
    //           <input
    //             type="email"
    //             className="form-control login-form-input email-input"
    //             aria-describedby="emailHelp"
    //             placeholder="Email"
    //             {...registerForgotField("forgotEmail", {
    //               required: "Email field cannot be empty",
    //             })}
    //           />
    //           <span className="input-icon icon-email"></span>
    //         </div>
    //         <div className="form-group my-5">
    //           <button type="submit" className="btn login-form-submit-button">
    //             Reset Password
    //           </button>
    //           {isLoadingForgotPassword && (
    //             <div
    //               className="spinner-border login-form-submit-loading"
    //               role="status"
    //             >
    //               <span className="visually-hidden">Loading...</span>
    //             </div>
    //           )}
    //         </div>
    //         <div className="form-group login-form-separator mt-3">
    //           <img src={loginSeparator} alt="login separator" />
    //         </div>
    //         <div className="form-group login-form-forgot-password-link mt-3 text-center">
    //           <p onClick={() => setForgotPasswordActive(false)}>Go to Login</p>
    //         </div>
    //       </form>
    //     )}
    //     <div className="right-side">
    //       <div className="navigation">
    //         <HomePageNavBar active="products" />
    //       </div>
    //       <div className="right-side-content d-flex align-items-center justify-content-center">
    //         <h1>
    //           Did You Know Our
    //           <br /> Extension Helps Sellers
    //           <br /> Increase Their Efficiency
    //           <br /> Over 2000%
    //         </h1>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div>


      <section className="john-login">

        <div className="contanir-by">
          <div className="main-john-login">
            <div className="john-tital">

              <h2>Welcome Back!!</h2>
              <p>Thanks For Resuming Your Journey, We are literally Glad</p>

              <div className="nazar-bhai-bhai">
                <p>Resume Your Past Journey’s</p>

                <div className="nazar-bhai-box">

                  <button>
                    <Link to="/" style={{ color: "white", textDecoration: "none" }}>
                      Home
                    </Link>
                  </button>
                  <button style={{ color: "white", textDecoration: "none" }}> <a
                    style={{ color: "white", textDecoration: "none" }}

                    href="https://chromewebstore.google.com/detail/unielogics/bagcekacfgdabdkhamefiohigjiepjpb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download Extension
                  </a></button>
                </div>
              </div>
            </div>
            {isLoading && (
              <div
                className="spinner-border login-form-submit-loading"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            <div className="john-form">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <h2>Log In</h2>

                <div className="main-nazar-fbhai-form">

                  <div className="my-input-group">
                    <label ><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path d="M36.6673 9.99984C36.6673 8.1665 35.1673 6.6665 33.334 6.6665H6.66732C4.83398 6.6665 3.33398 8.1665 3.33398 9.99984V29.9998C3.33398 31.8332 4.83398 33.3332 6.66732 33.3332H33.334C35.1673 33.3332 36.6673 31.8332 36.6673 29.9998V9.99984ZM33.334 9.99984L20.0007 18.3332L6.66732 9.99984H33.334ZM33.334 29.9998H6.66732V13.3332L20.0007 21.6665L33.334 13.3332V29.9998Z" fill="#F5F2FF" />
                    </svg> Email</label>
                    <input type="email"  {...register("email", {
                      required: "Email field cannot be empty",
                    })} />
                  </div>

                  <div className="my-input-group">
                    <label ><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <g clip-path="url(#clip0_347_782)">
                        <path d="M33.334 22.6668V20.0002C33.334 19.2929 33.053 18.6146 32.5529 18.1145C32.0528 17.6144 31.3746 17.3335 30.6673 17.3335H4.00065C3.29341 17.3335 2.61513 17.6144 2.11503 18.1145C1.61494 18.6146 1.33398 19.2929 1.33398 20.0002V36.0002C1.33398 36.7074 1.61494 37.3857 2.11503 37.8858C2.61513 38.3859 3.29341 38.6668 4.00065 38.6668H30.6673C31.3746 38.6668 32.0528 38.3859 32.5529 37.8858C33.053 37.3857 33.334 36.7074 33.334 36.0002V33.3335M33.334 22.6668H22.6673C21.2528 22.6668 19.8963 23.2287 18.8961 24.2289C17.8959 25.2291 17.334 26.5857 17.334 28.0002C17.334 29.4146 17.8959 30.7712 18.8961 31.7714C19.8963 32.7716 21.2528 33.3335 22.6673 33.3335H33.334M33.334 22.6668C34.7485 22.6668 36.105 23.2287 37.1052 24.2289C38.1054 25.2291 38.6673 26.5857 38.6673 28.0002C38.6673 29.4146 38.1054 30.7712 37.1052 31.7714C36.105 32.7716 34.7485 33.3335 33.334 33.3335M9.33398 17.3335V9.33349C9.33398 7.21176 10.1768 5.17693 11.6771 3.67664C13.1774 2.17635 15.2123 1.3335 17.334 1.3335C19.4557 1.3335 21.4905 2.17635 22.9908 3.67664C24.4911 5.17693 25.334 7.21176 25.334 9.33349V17.3335M32.0006 28.0002H34.6673M26.6673 28.0002H29.334M21.334 28.0002H24.0006" stroke="white" stroke-width="2" />
                      </g>
                      <defs>
                        <clipPath id="clip0_347_782">
                          <rect width="40" height="40" fill="white" />
                        </clipPath>
                      </defs>
                    </svg> Password</label>
                    <input type="password"  {...register("password", {
                      required: "Password field cannot be empty",
                    })} />
                  </div>

                  <div className="my-cheack-box">
                    <input type="checkbox" onChange={() => onRememberMeClick()}
                      checked={rememberMeChecked} />
                    <label >Remember My User Name</label>
                  </div>

                  <button>Sign In</button>
                </div>

              </form>
            </div>
          </div>

          <p className="vip-para">UnieLogics is the first ever multidimensional logistics company that allows the user to manage their ecommerce products with product research, accounting technology, supplier management.</p>
        </div>
      </section>

    </div>

  );
};
export default Login;
