import {
  ICheckoutPost,
  ICheckoutResponse,
  IRegisterPost,
  IRegisterResponse,
} from "../interfaces";

import { apiRoutes } from "utils";
import axios from "axios";

export const postRegisterRequest = async ({
  firstName,
  lastName,
  phoneNumber,
  email,
  username,
  password,
  referral,
}: IRegisterPost) => {
  const { data } = await axios.post<IRegisterResponse>(apiRoutes.REGISTER, {
    firstName,
    lastName,
    phoneNumber,
    email,
    username,
    password,
    referral,
  });
  return data;
};

export const postCheckoutRequest = async ({
  customerID,
  product,
}: ICheckoutPost) => {
  const { data } = await axios.post<ICheckoutResponse>(apiRoutes.CHECKOUT, {
    customerID,
    product,
  });
  return data;
};
