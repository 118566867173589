import "./App.css";

import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import Cookies from "js-cookie";

import {
  HomePage,
  Products,
  Profile,
  Login,
  Register,
  SurveyStep1,
  SurveyStep2,
  Charts,
  AdminView,
  AdminBlacklist,
  AdminFeedbackUrl,
  ProfitableProducts,
  ProfitableProductsDetails,
  SavedSearches,
  ShipmentPlan,
  ShipmentPlanItem,
} from "pages";
import { cookiesNames, localStorageNames, pageRoutes, UserContext } from "utils";
import { configureAxiosInterceptors, users } from "utils";
import {
  CheckoutErrorPage,
  PrivateRoutes,
  ThankYouPage,
  ShipmentPlanThankYouPage,
  ShipmentPlanErrorPage,
} from "components";

import "assets/styles/global.scss";
import Suppliers from "pages/suppliers";
import WarehouseOwners from "pages/adminWarehouseOwners";
import WarehousesMap from "pages/warehousesMap";

function App() {
  const queryClient = new QueryClient();

  configureAxiosInterceptors();

  let user: any = localStorage.getItem(localStorageNames.userObj);
  user = JSON.parse(user) ?? "";

  const {
    MAIN,
    LOGIN,
    REGISTER,
    CHARTS,
    THANK_YOU,
    CHECKOUT_ERROR,
    PROFILE,
    BUCKETS,
    SURVEY_STEP1,
    SURVEY_STEP2,
    ADMIN,
    FEEDBACK_URL,
    ADMIN_BLACKLIST,
    PROFITABLE_PRODUCTS,
    PROFITABLE_PRODUCTS_DETAILS,
    SAVED_SEARCHES,
    WAREHOUSE_OWNERS,
    SHIPMENT_PLANS,
    SHIPMENT_PLAN_ID,
    WAREHOUSES_MAP_PLAN_ID,
    SHIPMENT_PLAN_PAYMENT_THANK_YOU,
    SUPPLIERS,
    SHIPMENT_PLAN_PAYMENT_ERROR,
  } = pageRoutes;

  if (user) {
    user = {
      ...user,
      permissions: user.email === users.ADMIN_USER ? ["blacklist-view", "blacklist-add"] : null,
    };
  }

  if (!user) {
    Cookies.remove(cookiesNames.token);
    localStorage.removeItem(localStorageNames.surveyStep1);
    localStorage.removeItem(localStorageNames.userObj);
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={user}>
        <Routes>
          <Route path={MAIN} element={<HomePage />} />

          <Route path={LOGIN} element={<Login />} />

          <Route path={REGISTER} element={<Register />} />

          <Route path={CHARTS} element={<Charts />} />

          <Route path={THANK_YOU} element={<ThankYouPage />} />

          <Route path={CHECKOUT_ERROR} element={<CheckoutErrorPage />} />

          {/* ADD PRIVATE ROUTES HERE */}
          <Route element={<PrivateRoutes />}>
            <Route path={PROFILE} element={<Profile />} />

            <Route path={BUCKETS} element={<Products />} />

            <Route path={SURVEY_STEP1} element={<SurveyStep1 />} />

            <Route path={SURVEY_STEP2} element={<SurveyStep2 />} />

            <Route path={ADMIN} element={<AdminView />} />

            <Route path={FEEDBACK_URL} element={<AdminFeedbackUrl />} />

            <Route path={ADMIN_BLACKLIST} element={<AdminBlacklist />} />

            <Route path={PROFITABLE_PRODUCTS} element={<ProfitableProducts />} />

            <Route path={PROFITABLE_PRODUCTS_DETAILS} element={<ProfitableProductsDetails />} />

            <Route path={SAVED_SEARCHES} element={<SavedSearches />} />

            <Route path={WAREHOUSE_OWNERS} element={<WarehouseOwners />} />

            <Route path={SHIPMENT_PLANS} element={<ShipmentPlan />} />

            <Route path={SHIPMENT_PLAN_ID} element={<ShipmentPlanItem />} />

            <Route path={WAREHOUSES_MAP_PLAN_ID} element={<WarehousesMap />} />

            <Route path={SHIPMENT_PLAN_PAYMENT_THANK_YOU} element={<ShipmentPlanThankYouPage />} />

            <Route path={SHIPMENT_PLAN_PAYMENT_ERROR} element={<ShipmentPlanErrorPage />} />

            <Route path={SUPPLIERS} element={<Suppliers />} />
          </Route>
        </Routes>
      </UserContext.Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
