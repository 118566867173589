import axios from "axios";
import {
  IGetProfitableProductDetails,
  IGetProfitableProductDetailsResponse,
} from "../interfaces";
import { apiRoutes } from "utils";

export const getProfitableProductDetails = async ({
  email,
  folderId,
  asin,
}: IGetProfitableProductDetails) => {
  const { data } = await axios.get<IGetProfitableProductDetailsResponse>(
    `${apiRoutes.PROFITABLE_PRODUCT_DETAILS}?email=${email}&folderId=${folderId}&asin=${asin}`
  );
  return data;
};
