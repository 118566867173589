import { IWarehouseDetails } from "./interfaces";

const WarehouseDetails = ({ warehouseOwner }: IWarehouseDetails) => {
  return (
    <div className="ms-3 me-3 shipment-plan-warehouse-details">
      <h6>Warehouse:</h6>
      <ul className="p-0">
        <li className="light-gray">
          Address:{" "}
          <span className="white">
            {warehouseOwner?.businessAddress.address}
          </span>
        </li>
        <li className="light-gray">
          City:{" "}
          <span className="white">{warehouseOwner?.businessAddress.city}</span>
        </li>
        <li className="light-gray">
          State:{" "}
          <span className="white">{warehouseOwner?.businessAddress.state}</span>
        </li>
        <li className="light-gray">
          Zip Code:{" "}
          <span className="white">
            {warehouseOwner?.businessAddress.zipCode}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default WarehouseDetails;
