import "./styles.scss";

interface IStepperData {
  name: string;
  step: string;
  status: string;
}

interface IStepper {
  darkMode?: boolean;
  stepperData: IStepperData[];
}

const Stepper = ({ darkMode, stepperData }: IStepper) => {
  return (
    <ol className={`steps ${darkMode && "dark-mode"}`}>
      {stepperData.map((stepper, index) => (
        <li
          key={stepper.step}
          className={`step ${
            stepper.status === "completed"
              ? index <= 2 ||
                (index === 3 &&
                  stepperData[index - 1].status === "completed") ||
                (index > 3 && stepperData[index - 1].status !== "completed")
                ? "is-complete"
                : "is-partial-completed"
              : ""
          } ${stepper.status === "active" && "is-active"}`}
          data-step={stepper.step}
        >
          {stepper.name}
        </li>
      ))}
    </ol>
  );
};

export default Stepper;
