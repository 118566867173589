import { useMutation, useQuery } from "react-query";
import { IContextUser } from "common/interfaces";
import { Footer, FullScreenLoading, PageTitle, SidebarMenu } from "components";
import { Icon, Map } from "leaflet";
import { getWarehouseOwnersRequest } from "pages/adminWarehouseOwners/api";
import {
  IClosestWarehouses,
  IWarehouseOwners,
} from "pages/adminWarehouseOwners/interfaces";
import {
  getShipmentPlanRequest,
  updateShipmentPlanProductRequest,
} from "pages/shipmentPlan/api";
import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import { useNavigate } from "react-router-dom";
import {
  QUERY_KEYS,
  UserContext,
  ellipsis,
  getQueryParameterByName,
  pageRoutes,
} from "utils";
import warehouseImg from "../../assets/images/icons/leaflet-warehouse.png";
import supplierImg from "../../assets/images/icons/leafleft-supplier.png";
import { indexOf, isEmpty, isNull, orderBy } from "lodash";
import { warehousesMapHeaders } from "utils/configs";
import "./styles.scss";
import { toast } from "react-toastify";
import Bounds from "./Bounds";

const WarehousesMap: React.FC = () => {
  const planId = getQueryParameterByName("id");
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;

  const [shipmentPlan, setShipmentPlan] = useState<IShipmentPlan[]>([]);
  const [warehouseOwners, setWarehouseOwners] = useState<IWarehouseOwners[]>(
    []
  );
  const [closestWarehouses, setClosestWarehouses] = useState<
    IClosestWarehouses[]
  >([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    IClosestWarehouses | undefined
  >(undefined);
  const [map, setMap] = useState<Map | null>(null);

  const popUpRef = useRef(null);

  const navigate = useNavigate();

  const {
    data: shipmentPlanData,
    error: shipmentPlanDataError,
    isLoading: isLoadingShipmentPlan,
  } = useQuery(
    QUERY_KEYS.GET_SHIPMENT_PLAN,
    () => getShipmentPlanRequest(email, planId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const {
    data: warehousesData,
    error: warehousesDataError,
    isLoading: isLoadingWarehouses,
  } = useQuery(
    QUERY_KEYS.GET_WAREHOUSE_OWNERS,

    () => getWarehouseOwnersRequest(email),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (shipmentPlanDataError || warehousesDataError) {
      navigate(`${pageRoutes.SHIPMENT_PLANS}`);
    }

    if (shipmentPlanData?.response) {
      setShipmentPlan(shipmentPlanData.response);
    }

    if (warehousesData?.response) {
      setWarehouseOwners(warehousesData.response);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipmentPlanDataError,
    warehousesData,
    warehousesDataError,
    shipmentPlanData,
  ]);

  const {
    mutate: updateShipmentProduct,
    isLoading: isLoadingUpdateShipmentPlanProduct,
  } = useMutation(updateShipmentPlanProductRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully updated shipment plan warehouse.");
      }
      navigate(`${pageRoutes.SHIPMENT_PLAN_ID}?id=${planId}`);
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error updating your plan warehouse."
      );
    },
  });

  const shouldDisplayLoader =
    isLoadingShipmentPlan ||
    isLoadingWarehouses ||
    isLoadingUpdateShipmentPlanProduct;
  const shouldDisplayMap = !shouldDisplayLoader && !isEmpty(shipmentPlan);

  const acceptWarehouseOwner = () => {
    if (planId && selectedWarehouse) {
      const dataToUpdateShipmentPlan = {
        email,
        shipmentPlanId: planId,
        warehouseOwner: {
          wOwnerEmail: selectedWarehouse.email,
          _id: selectedWarehouse._id || "",
        },
      };

      updateShipmentProduct(dataToUpdateShipmentPlan);
    }
  };

  const onChangeWarehouse = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedWarehouse(
      closestWarehouses.find((warehouse) => warehouse._id === e.target.value)
    );
  };

  const copyToClipboard = (text: string | number) => {
    navigator.clipboard
      .writeText(JSON.stringify(text))
      .then(() => console.log("Text copied to clipboard"))
      .catch((err) => console.error("Unable to copy text to clipboard", err));
  };

  useEffect(() => {
    if (!isNull(map) && !isEmpty(shipmentPlan)) {
      const sortedWarehousesCoordinates: IClosestWarehouses[] = orderBy(
        warehouseOwners.map((warehouse) => {
          const lat = Number(warehouse.warehouses[0].businessAddress.lat);
          const long = Number(warehouse.warehouses[0].businessAddress.long);
          const supplierLat =
            shipmentPlan[0].products[0]?.supplier.supplierAddress.lat;
          const supplierLong =
            shipmentPlan[0].products[0]?.supplier.supplierAddress.long;

          const distance = (
            map.distance([supplierLat, supplierLong], [lat, long]) *
            0.000621371192
          ).toFixed(2);

          return {
            ...warehouse.warehouses[0].businessAddress,
            name: warehouse.warehouses[0].name,
            _id: warehouse.warehouses[0]._id,
            email: warehouse.email,
            distance,
          };
        }),
        ["distance"],
        ["asc"]
      ).slice(0, 3);

      setClosestWarehouses(sortedWarehousesCoordinates);
      setSelectedWarehouse(sortedWarehousesCoordinates[0]);
    }
  }, [shipmentPlan, warehouseOwners, map]);

  return (
    <>
      <div className="content">
        {shouldDisplayLoader ? (
          <FullScreenLoading />
        ) : (
          <div className="container-fluid">
            <div className="sidebar">
              <SidebarMenu sidebarOnly={true} />
            </div>
            <PageTitle pageTitle="Warehouses map" />

            <div className="wm-container d-flex flex-column">
              {shouldDisplayMap && (
                <div className="wm-map-container d-flex mb-4">
                  <MapContainer
                    className="wm-leaflet-map"
                    style={{ height: "100%", width: "100%" }}
                    center={[
                      shipmentPlan[0].products[0]?.supplier.supplierAddress.lat,
                      shipmentPlan[0].products[0]?.supplier.supplierAddress
                        .long,
                    ]}
                    zoom={10}
                    scrollWheelZoom
                    ref={setMap}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {!isEmpty(shipmentPlan[0].products[0].supplier) && (
                      <Marker
                        key={
                          shipmentPlan[0].products[0].supplier.supplierAddress
                            .lat
                        }
                        position={[
                          shipmentPlan[0].products[0].supplier.supplierAddress
                            .lat,
                          shipmentPlan[0].products[0].supplier.supplierAddress
                            .long,
                        ]}
                        icon={
                          new Icon({
                            iconUrl: supplierImg,
                            iconSize: [49, 49],
                          })
                        }
                      >
                        <Popup className="wm-popup" ref={popUpRef}>
                          <div className="row">
                            <div className="col-sm">
                              <div className="table-responsive">
                                <table
                                  className="table table-sm"
                                  style={{ overflowX: "scroll" }}
                                >
                                  <thead className="sticky-header">
                                    <tr className="no-interaction">
                                      {warehousesMapHeaders.map((header) => (
                                        <th
                                          className="text-center"
                                          key={header.name}
                                        >
                                          {header.name}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {shipmentPlan[0].products.map((product) => (
                                      <tr
                                        key={`${product.asin}-products`}
                                        className="product-details"
                                      >
                                        <td className="align-middle text-center white-space-nowrap">
                                          <a
                                            href={`https://www.amazon.com/dp/${product.asin}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {product.asin}
                                          </a>
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          <img
                                            src={product.imageUrl}
                                            alt="product-thumb"
                                          />
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {ellipsis(product.title, 50)}
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {isEmpty(product.unitsPerBox)
                                            ? "-"
                                            : product.unitsPerBox}
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {product.boxWidth}x{product.boxHeight}
                                          x{product.boxLength}
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {product.amazonPrice}$
                                        </td>
                                        <td className="align-middle text-center white-space-nowrap">
                                          {
                                            shipmentPlan[0].products[0].supplier
                                              .supplierName
                                          }
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </Marker>
                    )}

                    {!isEmpty(selectedWarehouse) && (
                      <Marker
                        key={selectedWarehouse.lat}
                        position={[
                          selectedWarehouse.lat,
                          selectedWarehouse.long,
                        ]}
                        icon={
                          new Icon({
                            iconUrl: warehouseImg,
                            iconSize: [49, 49],
                            iconAnchor: [12, 41],
                          })
                        }
                      />
                    )}

                    {!isEmpty(selectedWarehouse) && (
                      <>
                        <Polyline
                          positions={[
                            [
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.lat,
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.long,
                            ],
                            [selectedWarehouse.lat, selectedWarehouse.long],
                          ]}
                          weight={5}
                        >
                          <Popup className="wm-popup" ref={popUpRef}>
                            <a
                              id="get-directions-link"
                              href={`https://www.google.com/maps/dir/?api=1&origin=${shipmentPlan[0].products[0].supplier.supplierAddress.lat},${shipmentPlan[0].products[0].supplier.supplierAddress.long}&destination=${selectedWarehouse.lat},${selectedWarehouse.long}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Get directions
                            </a>
                          </Popup>
                        </Polyline>

                        <Bounds
                          coords={[
                            [
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.lat,
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.long,
                            ],
                            [selectedWarehouse.lat, selectedWarehouse.long],
                          ]}
                        />
                      </>
                    )}
                  </MapContainer>
                </div>
              )}

              <div className="wm-addresses-container d-flex flex-wrap justify-content-center">
                <div>
                  <h2>From</h2>

                  <div className="wm-supplier-address wm-address-box ">
                    {!isEmpty(shipmentPlan[0]) && (
                      <>
                        <h2>Supplier</h2>
                        <div className="d-flex flex-column">
                          <span>
                            City:{" "}
                            {
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.city
                            }
                          </span>

                          <span>
                            State:{" "}
                            {
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.state
                            }
                          </span>

                          <span>
                            Street:{" "}
                            {
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.street
                            }
                          </span>

                          <span>
                            Zipcode:{" "}
                            {
                              shipmentPlan[0].products[0].supplier
                                .supplierAddress.zipCode
                            }
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className="wm-pointing-arrow">
                  <i className="wm-fa fa fa-arrow-right"></i>
                </div>

                <div className="wm-pointing-arrow-mobile">
                  <i className="wm-fa fa fa-arrow-down"></i>
                </div>

                {!isEmpty(selectedWarehouse) && (
                  <div>
                    <h2>To</h2>
                    <div className="wm-warehouse-address wm-address-box">
                      <h2>Warehouse</h2>

                      <div className="d-flex flex-column">
                        <span
                          onClick={() =>
                            copyToClipboard(selectedWarehouse.name)
                          }
                        >
                          Business Name: {selectedWarehouse.name}
                          <i
                            className="fa fa-clipboard ms-2 fa-xs"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span
                          onClick={() =>
                            copyToClipboard(selectedWarehouse.city)
                          }
                        >
                          City: {selectedWarehouse.city}
                          <i
                            className="fa fa-clipboard ms-2 fa-xs"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span
                          onClick={() =>
                            copyToClipboard(selectedWarehouse.address)
                          }
                        >
                          Address: {selectedWarehouse.address}
                          <i
                            className="fa fa-clipboard ms-2 fa-xs"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span
                          onClick={() =>
                            copyToClipboard(selectedWarehouse.state)
                          }
                        >
                          State: {selectedWarehouse.state}
                          <i
                            className="fa fa-clipboard ms-2 fa-xs"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span
                          onClick={() =>
                            copyToClipboard(selectedWarehouse.zipCode)
                          }
                        >
                          Zipcode: {selectedWarehouse.zipCode}
                          <i
                            className="fa fa-clipboard ms-2 fa-xs"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>

                    {!isEmpty(closestWarehouses) && (
                      <div className="wm-warehouse-select mb-4">
                        <select
                          className="form-select dropdown-container"
                          onChange={onChangeWarehouse}
                        >
                          {closestWarehouses.map((warehouse) => (
                            <option
                              value={warehouse._id}
                              key={indexOf(closestWarehouses, warehouse)}
                            >
                              {warehouse.name} ({warehouse.distance} miles)
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                )}
                <div className="wm-submit ms-4 d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-generic btn-success"
                    onClick={acceptWarehouseOwner}
                  >
                    Accept Warehouse
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default WarehousesMap;
