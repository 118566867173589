import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";

import {
  AmazonDataCard,
  AmazonDataShipmentPlanDetails,
  FbaAndOtherFilesUpload,
  Footer,
  FullScreenLoading,
  PageTitle,
  ShipmentPlanProduct,
  SidebarMenu,
  Stepper,
  WarehouseDetails,
} from "components";
import {
  QUERY_KEYS,
  UserContext,
  getQueryParameterByName,
  pageRoutes,
} from "utils";
import {
  deleteShipmentPlanFileRequest,
  deleteShipmentPlanProductRequest,
  getShipmentPlanRequest,
  postShipmentPlanFilesRequest,
  postShipmentPlanPaymentRequest,
  updateShipmentPlanProductRequest,
} from "./api";
import { IContextUser } from "common/interfaces";
import { IShipmentPlan } from "pages/shipmentPlans/interfaces";
import { isEmpty, isEqual } from "lodash";
import {
  FileTypes,
  IAdditionalShipmentInputs,
  IDeleteShipmentPlanProductResponse,
  IPayShipmentPlan,
  IShipmentPlanFiles,
  IUpdateShipmentPlanProduct,
} from "./interfaces";
import { uuidv4 } from "utils/globals";
import { useNavigate } from "react-router-dom";
import { pricing } from "./pricing";

import "./styles.scss";
import { getAreFieldsInvalid } from "./utils";

let stepperData = [
  {
    name: "Add Products to S. P.",
    step: "1",
    status: "completed",
  },
  {
    name: "Create S. P.",
    step: "2",
    status: "completed",
  },
  {
    name: "Find Warehouse",
    step: "3",
    status: "active",
  },
  {
    name: "Amazon Replenish",
    step: "4",
    status: "",
  },
  {
    name: "Payment",
    step: "5",
    status: "",
  },
  {
    name: "Warehouse Processing",
    step: "6",
    status: "",
  },
];

const ShipmentPlan: React.FC = () => {
  const planId = getQueryParameterByName("id");
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [shipmentPlan, setShipmentPlan] = useState<IShipmentPlan[]>([]);
  const [shipmentAdditionalInputs, setShipmentAdditionalInputs] =
    useState<IAdditionalShipmentInputs>({});
  const [areFieldsEmpty, setAreFieldsEmpty] = useState(false);
  const [initialEnteredValues, setInitialEnteredValues] = useState<string[]>(
    []
  );
  const [fbaFiles, setFbaFiles] = useState<IShipmentPlanFiles[]>([]);
  const [otherFiles, setOthersFile] = useState<IShipmentPlanFiles[]>([]);
  const [titleInput, setTitleInput] = useState<string>("");
  const [shouldDisplayEditTitleInput, setShouldDisplayEditTitleInput] =
    useState(false);
  const [orderNoInput, setOrderNoInput] = useState<string>("");
  const [receiptNo, setReceiptNo] = useState<string>("");
  const [orderDate, setOrderDate] = useState<Date | null>(null);
  const [shouldDisplayOrderNoError, setShouldDisplayOrderNoError] =
    useState(false);
  const [shouldDisplayReceiptNoError, setShouldDisplayReceiptNoError] =
    useState(false);
  const [packagingLimitsError, setPackagingLimitsError] = useState<
    Record<string, boolean>
  >({});
  const [additionalInputsSection, setAdditionalInputsSection] = useState<
    Record<string, boolean>
  >({});
  const [showAdditionalPlanInputs, setShowAdditionalPlanInputs] = useState<
    Record<string, boolean>
  >({});
  const [shouldGreyOutPaymentBtn, setShouldGreyOutPaymentBtn] = useState(true);
  const [totalPriceCb, setTotalPriceCb] = useState<string>("-");

  const {
    data,
    error,
    isLoading: isLoadingShipmentPlan,
  } = useQuery(
    QUERY_KEYS.GET_SHIPMENT_PLAN,
    () => getShipmentPlanRequest(email, planId),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const {
    mutate: updateShipmentProduct,
    isLoading: isLoadingUpdateShipmentPlanProduct,
  } = useMutation(updateShipmentPlanProductRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully updated shipment plan products.");
      }

      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.GET_SHIPMENT_PLAN,
      });
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error updating your plan products."
      );
    },
  });

  const {
    mutate: deleteShipmentPlanProduct,
    isLoading: isLoadingDeleteShipmentPlanProduct,
  } = useMutation(deleteShipmentPlanProductRequest, {
    onSuccess: (data: IDeleteShipmentPlanProductResponse) => {
      if (data.status) {
        toast.success("Successfully deleted product from shipment plan.");
        if (isEmpty(data?.response[0]?.products)) {
          window.location.href = pageRoutes.SHIPMENT_PLANS;
        } else
          queryClient.invalidateQueries({
            queryKey: QUERY_KEYS.GET_SHIPMENT_PLAN,
          });
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error deleting your product."
      );
      return;
    },
  });

  const {
    mutate: postShipmentPlanFiles,
    isLoading: isLoadingPostShipmentPlanFiles,
  } = useMutation(postShipmentPlanFilesRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully uploaded file");

        const parsedFbaFiles: IShipmentPlanFiles[] =
          data?.response[0].files.fbaLabels?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));
        const otherFiles: IShipmentPlanFiles[] =
          data?.response[0].files.otherFiles?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));

        setFbaFiles(parsedFbaFiles);
        setOthersFile(otherFiles);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ?? "There was an error uploading your file."
      );
    },
  });

  const {
    mutate: deleteShipmentPlanFile,
    isLoading: isLoadingDeleteShipmentPlanFile,
  } = useMutation(deleteShipmentPlanFileRequest, {
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Successfully deleted file");

        const parsedFbaFiles: IShipmentPlanFiles[] =
          data.response[0].files.fbaLabels?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));
        const otherFiles: IShipmentPlanFiles[] =
          data.response[0].files.otherFiles?.map((file) => ({
            filename: file.filename,
            _id: file._id,
          }));

        setFbaFiles(parsedFbaFiles);
        setOthersFile(otherFiles);

        const updatedShipmentPlan = [...shipmentPlan];
        updatedShipmentPlan[0].files.fbaLabels = fbaFiles;
        updatedShipmentPlan[0].files.otherFiles = otherFiles;
        setShipmentPlan(updatedShipmentPlan);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ?? "There was an error deleting your file."
      );
    },
  });

  const { mutate: postShipmentPlanPayment, isLoading: isLoadingPayment } =
    useMutation(postShipmentPlanPaymentRequest, {
      onSuccess: (data) => {
        console.log(data.response.payment_intent);
        if (data.status) {
          console.log(data.response.url);
          localStorage.setItem(
            "asinmice-payment-intent-id",
            data.response.payment_intent
          );
          window.location.replace(data.response.url);
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error trying to redirect to payment. Please try again and if the problem persists please contact us. "
        );
      },
    });

  const shouldDisplayLoader =
    isLoadingDeleteShipmentPlanProduct ||
    isLoadingShipmentPlan ||
    isLoadingUpdateShipmentPlanProduct ||
    isLoadingPostShipmentPlanFiles ||
    isLoadingDeleteShipmentPlanFile ||
    isLoadingPayment;
  const shouldRenderShipmentPlan =
    !shouldDisplayLoader && !isEmpty(shipmentPlan);
  const shouldDisplayFbaTitle =
    !isEmpty(fbaFiles) && !isLoadingPostShipmentPlanFiles;
  const shouldDisplaySkaTitle =
    !isEmpty(otherFiles) && !isLoadingPostShipmentPlanFiles;
  const shouldTriggerOrderNoError =
    (areFieldsEmpty && isEmpty(orderNoInput)) || shouldDisplayOrderNoError;
  const shouldTriggerReceiptNoError =
    (areFieldsEmpty && isEmpty(receiptNo)) || shouldDisplayReceiptNoError;

  const handleFindWarehouseRedirect = () =>
    navigate(`${pageRoutes.WAREHOUSES_MAP_PLAN_ID}?id=${planId}`);

  const handleValidation = () => {
    const isWarehouseOwnerEmpty = shipmentPlan[0].warehouseOwner
      ? Object.values(shipmentPlan[0].warehouseOwner).some((values) =>
          isEmpty(values)
        )
      : true;
    const areAllFieldsEmpty = getAreFieldsInvalid(
      shipmentAdditionalInputs,
      receiptNo,
      orderNoInput,
      fbaFiles,
      titleInput,
      shouldDisplayReceiptNoError,
      shouldDisplayOrderNoError
    );
    let asinMiceProfile = localStorage.getItem("asinmice-obj");
    asinMiceProfile = JSON.parse(asinMiceProfile!!);
    //@ts-ignore
    const { customerID } = asinMiceProfile;

    if (orderNoInput.length < 6) {
      setShouldDisplayOrderNoError(true);
      toast.error("Order no must be at least 6 characters long");
    } else if (receiptNo.length < 6) {
      setShouldDisplayReceiptNoError(true);
      toast.error("Receipt no must be at least 6 characters long");
    } else if (areAllFieldsEmpty) {
      const makeAllFieldsVisible = { ...additionalInputsSection };
      Object.keys(makeAllFieldsVisible).forEach(
        (v) => (makeAllFieldsVisible[v] = true)
      );
      setAdditionalInputsSection(makeAllFieldsVisible);
      setAreFieldsEmpty(true);
    } else if (isWarehouseOwnerEmpty) {
      toast.error("Please find your nearest warehouse");
    } else {
      const payload: IPayShipmentPlan = {
        amount: parseFloat(totalPriceCb),
        customerId: customerID,
        shipmentPlanId: shipmentPlan[0]._id,
        email,
      };
      console.log(payload);
      postShipmentPlanPayment(payload);
    }
  };

  const handleOnUpdate = () => {
    const areValuesIdentical = isEqual(
      Object.values(shipmentAdditionalInputs),
      Object.values(initialEnteredValues)
    );
    const shouldUpdateDate = isEqual(
      orderDate,
      new Date(shipmentPlan[0].orderDate)
    );
    const shouldUpdateTitle = isEqual(
      titleInput,
      shipmentPlan[0].shipmentTitle
    );
    const shouldUpdateOrderNo = isEqual(orderNoInput, shipmentPlan[0].orderNo);
    const shouldUpdateReceiptNo = isEqual(receiptNo, shipmentPlan[0].receiptNo);
    const areAllFieldsIdentical =
      areValuesIdentical &&
      shouldUpdateTitle &&
      shouldUpdateOrderNo &&
      shouldUpdateDate &&
      shouldUpdateReceiptNo;

    if (areAllFieldsIdentical) {
      toast.error("Entered values must be different than the initial ones.");
    } else {
      const payload: IUpdateShipmentPlanProduct = {
        email,
        orderNo: orderNoInput,
        receiptNo,
        orderDate: orderDate!!.toISOString(),
        shipmentTitle: titleInput,
        shipmentPlanId: shipmentPlan[0]._id,
        products: shipmentPlan[0].products,
      };
      updateShipmentProduct(payload);
    }
  };

  const handleDisplaySpecificPlan = (planId: string) => {
    const visibileInputs = { ...additionalInputsSection };

    visibileInputs[`product-${planId}`] = visibileInputs[`product-${planId}`]
      ? !visibileInputs[`product-${planId}`]
      : true;

    setAdditionalInputsSection(visibileInputs);
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number | undefined
  ) => {
    const result = e.target.value.replace(/[^\d.,]/g, "");
    const updatedResult: IAdditionalShipmentInputs = {
      ...shipmentAdditionalInputs,
    };
    const updatedPackagingErrors: Record<string, boolean> = {
      ...packagingLimitsError,
    };

    switch (e.target.id) {
      case "sp-title-input":
        updatedResult["sp-title-input"] = e.target.value;
        setTitleInput(e.target.value);
        break;

      case "sp-orderNo-input":
        updatedResult["sp-orderNo-input"] = e.target.value;
        setOrderNoInput(e.target.value);

        if (e.target.value.length < 6) {
          setShouldDisplayOrderNoError(true);
        } else {
          setShouldDisplayOrderNoError(false);
        }
        break;

      case "sp-receiptNo-input":
        updatedResult["sp-receiptNo-input"] = e.target.value;
        setReceiptNo(e.target.value);

        if (e.target.value.length < 6) {
          setShouldDisplayReceiptNoError(true);
        } else {
          setShouldDisplayReceiptNoError(false);
        }
        break;

      case `sp-unitsPerBox-${index}`:
        updatedResult[`sp-unitsPerBox-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].unitsPerBox = result;
          return updatedProduct;
        });
        break;

      case `sp-fnsku-${index}`:
        updatedResult[`sp-fnsku-${index}`] = e.target.value;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].fnsku = e.target.value;
          return updatedProduct;
        });
        break;

      case `sp-boxes-${index}`:
        updatedResult[`sp-boxes-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxes = result;
          return updatedProduct;
        });
        break;

      case `sp-weightPerBox-${index}`:
        updatedResult[`sp-weightPerBox-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].weightPerBox = result;
          return updatedProduct;
        });
        break;

      case `sp-upc-${index}`:
        updatedResult[`sp-upc-${index}`] = e.target.value;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].upc = e.target.value;
          return updatedProduct;
        });
        break;

      case `sp-width-${index}`:
        updatedResult[`sp-width-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxWidth = result;
          return updatedProduct;
        });
        break;

      case `sp-height-${index}`:
        updatedResult[`sp-height-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxHeight = result;
          return updatedProduct;
        });
        break;

      case `sp-length-${index}`:
        updatedResult[`sp-length-${index}`] = result;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].boxLength = result;
          return updatedProduct;
        });
        break;

      case `sp-shrink-wrap-${index}`:
        updatedResult[`sp-special-packaging-${index}`] = {
          amount: "",
          answer: false,
        };
        updatedResult[`sp-shrink-wrap-${index}`] = {
          amount: result,
          answer: true,
        };
        if (!isEmpty(result) && (Number(result) > 8 || Number(result) <= 0)) {
          updatedPackagingErrors[`sp-shrink-wrap-${index}`] = true;
        } else {
          updatedPackagingErrors[`sp-shrink-wrap-${index}`] = false;
        }

        setPackagingLimitsError(updatedPackagingErrors);
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].shrinkWrap = {
            answer: true,
            amount: result,
          };
          updatedProduct[0].products[index!!].specialPackaging = {
            answer: false,
            amount: "",
          };
          return updatedProduct;
        });
        break;

      case `sp-special-packaging-${index}`:
        updatedResult[`sp-special-packaging-${index}`] = {
          amount: "",
          answer: false,
        };
        updatedResult[`sp-special-packaging-${index}`] = {
          amount: result,
          answer: true,
        };
        if (!isEmpty(result) && (Number(result) > 8 || Number(result) <= 0)) {
          updatedPackagingErrors[`sp-special-packaging-${index}`] = true;
        } else {
          updatedPackagingErrors[`sp-special-packaging-${index}`] = false;
        }

        setPackagingLimitsError(updatedPackagingErrors);
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].specialPackaging = {
            answer: true,
            amount: result,
          };
          updatedProduct[0].products[index!!].shrinkWrap = {
            answer: false,
            amount: "",
          };
          return updatedProduct;
        });
        break;

      case `sp-comments-${index}`:
        updatedResult[`sp-comments-${index}`] = e.target.value;
        setShipmentAdditionalInputs(updatedResult);
        setShipmentPlan((prevState) => {
          const updatedProduct = prevState;
          updatedProduct[0].products[index!!].comments = e.target.value;
          return updatedProduct;
        });
        break;

      default:
        break;
    }

    const shouldEnablePayBtn = !getAreFieldsInvalid(
      updatedResult,
      receiptNo,
      orderNoInput,
      fbaFiles,
      titleInput,
      shouldDisplayReceiptNoError,
      shouldDisplayOrderNoError
    );

    if (shouldEnablePayBtn) {
      setAreFieldsEmpty(false);
    }
  };

  const onDeleteShipmentPlanProductCb = (
    shipmentPlanId: string,
    productId: string
  ) => {
    deleteShipmentPlanProduct({ email, shipmentPlanId, productId });
  };

  const handleOnFileSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileType: string
  ) => {
    if (e.target.files && planId) {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("fileType", fileType);
      formData.append("shipmentPlanId", planId);

      for (let i = 0; i < e.target.files.length; i++) {
        formData.append("files", e.target.files[i]);
      }
      setAreFieldsEmpty(false);
      postShipmentPlanFiles(formData);
    }
  };

  const handleOnDeleteFile = (
    file: IShipmentPlanFiles,
    fileType: FileTypes
  ) => {
    const fileToDelete = {
      email,
      fileToDelete: file._id,
      fileType,
      shipmentPlanId: planId,
    };

    deleteShipmentPlanFile(fileToDelete);
  };

  const handleOnClickEditTitle = () => {
    setShouldDisplayEditTitleInput((prevState) => !prevState);
    if (shouldDisplayEditTitleInput) {
      setTitleInput(shipmentPlan[0].shipmentTitle);
    }
  };

  const copyToClipBoard = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied to clipboard:", textToCopy);
    } catch (err) {
      console.error("Unable to copy text to clipboard:", err);
    }
  };

  const handleRadioButtonsClick = (radioButtonInput: string, index: number) => {
    const radioButtonsInputs = { ...showAdditionalPlanInputs };
    const updatedAdditionalInputs = { ...shipmentAdditionalInputs };

    radioButtonsInputs[radioButtonInput] =
      !radioButtonsInputs[radioButtonInput];

    if (radioButtonInput.includes("sp-shrink-wrap")) {
      radioButtonsInputs[`sp-special-packaging-${index}`] = false;
    } else if (radioButtonInput.includes("sp-special-packaging")) {
      radioButtonsInputs[`sp-shrink-wrap-${index}`] = false;
    }

    updatedAdditionalInputs[radioButtonInput].answer =
      radioButtonsInputs[radioButtonInput];
    updatedAdditionalInputs[radioButtonInput].amount = "";

    setShowAdditionalPlanInputs(radioButtonsInputs);
    setShipmentAdditionalInputs(updatedAdditionalInputs);
    setShipmentPlan((prevState) => {
      prevState[0].products[index!!].specialPackaging.answer =
        radioButtonsInputs[`sp-special-packaging-${index}`];
      prevState[0].products[index!!].specialPackaging.amount = "";
      prevState[0].products[index!!].shrinkWrap.answer =
        radioButtonsInputs[`sp-shrink-wrap-${index}`];
      prevState[0].products[index!!].shrinkWrap.amount = "";
      return prevState;
    });
  };

  useEffect(() => {
    if (error) {
      window.location.href = pageRoutes.SHIPMENT_PLANS;
    } else if (data?.response) {
      const hasWarehouseLocation = data?.response[0].warehouseOwner
        ? data?.response[0].warehouseOwner?.businessAddress?.long !== "" &&
          data?.response[0].warehouseOwner?.businessAddress?.lat !== ""
        : false;

      const amazonData =
        data?.response[0].amazonData?.workFlowId !== "" &&
        data?.response[0].products.length;

      const paymentDone = data?.response[0].payment.paid;

      const updatedStepperData = [...stepperData];
      const step3Index = 2;
      const step4Index = 3;
      const step5Index = 4;
      const step6Index = 5;

      if (hasWarehouseLocation) {
        updatedStepperData[step3Index].status = "completed";
      } else {
        updatedStepperData[step3Index].status = "active";
      }

      if (amazonData) {
        updatedStepperData[step4Index].status = "completed";
      } else {
        updatedStepperData[step4Index].status = "active";
      }

      if (paymentDone) {
        updatedStepperData[step5Index].status = "completed";
      } else {
        updatedStepperData[step5Index].status = "active";
      }

      if (hasWarehouseLocation && amazonData && paymentDone) {
        updatedStepperData[step6Index].status = "active";
      } else {
        updatedStepperData[step6Index].status = "";
      }

      const parsedFbaFiles: IShipmentPlanFiles[] =
        data?.response[0].files.fbaLabels?.map((file) => ({
          filename: file.filename,
          _id: file._id,
        }));
      const otherFiles: IShipmentPlanFiles[] =
        data?.response[0].files.otherFiles?.map((file) => ({
          filename: file.filename,
          _id: file._id,
        }));
      setShipmentPlan(data?.response);
      setFbaFiles(parsedFbaFiles);
      setOthersFile(otherFiles);
      setTitleInput(data?.response[0].shipmentTitle);
      setOrderNoInput(data?.response[0].orderNo);
      setReceiptNo(data?.response[0].receiptNo);
      setOrderDate(new Date(data?.response[0].orderDate));

      if (data?.response[0].orderNo) {
        setShouldDisplayOrderNoError(false);
      }
      if (data?.response[0].receiptNo) {
        setShouldDisplayReceiptNoError(false);
      }

      let additionalInputs: IAdditionalShipmentInputs = {};
      const enteredValues: any = [];
      const radioButtonsInputs: Record<string, boolean> = {};
      const productInputsSection: Record<string, boolean> = {};

      data?.response[0].products.forEach((product, index) => {
        const {
          _id,
          unitsPerBox,
          boxWidth,
          boxHeight,
          boxLength,
          fnsku,
          boxes,
          weightPerBox,
          upc,
          comments,
          specialPackaging,
          shrinkWrap,
        } = product;
        if (shrinkWrap.answer) {
          radioButtonsInputs[`sp-shrink-wrap-${index}`] = true;
          setShowAdditionalPlanInputs(radioButtonsInputs);
        }

        if (specialPackaging.answer) {
          radioButtonsInputs[`sp-special-packaging-${index}`] = true;
          setShowAdditionalPlanInputs(radioButtonsInputs);
        }

        productInputsSection[`product-${_id}`] =
          additionalInputsSection[`product-${_id}`] || false;

        enteredValues.push(upc);
        enteredValues.push(weightPerBox);
        enteredValues.push(boxes);
        enteredValues.push(fnsku);
        enteredValues.push(unitsPerBox);
        enteredValues.push(boxWidth);
        enteredValues.push(boxHeight);
        enteredValues.push(boxLength);
        enteredValues.push(comments);
        enteredValues.push({ ...specialPackaging });
        enteredValues.push({ ...shrinkWrap });

        additionalInputs[`sp-upc-${index}`] = upc;
        additionalInputs[`sp-weightPerBox-${index}`] = weightPerBox;
        additionalInputs[`sp-boxes-${index}`] = boxes;
        additionalInputs[`sp-fnsku-${index}`] = fnsku;
        additionalInputs[`sp-unitsPerBox-${index}`] = unitsPerBox;
        additionalInputs[`sp-width-${index}`] = boxWidth;
        additionalInputs[`sp-height-${index}`] = boxHeight;
        additionalInputs[`sp-length-${index}`] = boxLength;
        additionalInputs[`sp-comments-${index}`] = comments;
        additionalInputs[`sp-special-packaging-${index}`] = {
          ...specialPackaging,
        };
        additionalInputs[`sp-shrink-wrap-${index}`] = { ...shrinkWrap };
      });

      const areAllFieldsEmpty = getAreFieldsInvalid(
        additionalInputs,
        data?.response[0].receiptNo,
        data?.response[0].orderNo,
        parsedFbaFiles,
        titleInput,
        shouldDisplayReceiptNoError,
        shouldDisplayOrderNoError
      );

      if (!areAllFieldsEmpty) {
        setShouldGreyOutPaymentBtn(false);
      }

      setAdditionalInputsSection(productInputsSection);
      setInitialEnteredValues(enteredValues);
      setShipmentAdditionalInputs(additionalInputs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  useEffect(() => {
    if (areFieldsEmpty) {
      toast.error(
        "Please complete all the fields, including upload of FBA files from amazon"
      );
    }
  }, [areFieldsEmpty]);

  useEffect(() => {
    const shouldDisableGreyOutBtn = !getAreFieldsInvalid(
      shipmentAdditionalInputs,
      receiptNo,
      orderNoInput,
      fbaFiles,
      titleInput,
      shouldDisplayReceiptNoError,
      shouldDisplayOrderNoError
    );

    if (shouldDisableGreyOutBtn) {
      setShouldGreyOutPaymentBtn(false);
    } else setShouldGreyOutPaymentBtn(true);
  }, [
    titleInput,
    shipmentAdditionalInputs,
    fbaFiles,
    receiptNo,
    orderNoInput,
    shouldDisplayReceiptNoError,
    shouldDisplayOrderNoError,
  ]);

  return (
    <>
      <div className="content">
        {shouldDisplayLoader && <FullScreenLoading />}
        <div className="container-fluid shipment-plans">
          <div className="sidebar">
            <SidebarMenu active="shipment-plans" sidebarOnly={true} />
          </div>
          <div className="d-flex">
            <PageTitle pageTitle={shipmentPlan[0]?.shipmentTitle} />

            <div
              className={`d-flex edit-title-input ms-3 dark-input ${
                areFieldsEmpty && isEmpty(titleInput) && "form-error"
              }`}
            >
              {shouldDisplayEditTitleInput && !shouldDisplayLoader && (
                <input
                  id="sp-title-input"
                  value={titleInput}
                  onChange={(e) => handleOnChange(e, undefined)}
                />
              )}

              <span className="icon-wrapper" onClick={handleOnClickEditTitle}>
                {!shouldDisplayEditTitleInput ? (
                  <i className="fa fa-edit mt-2 c-pointer"></i>
                ) : (
                  <i className="fa fa-close ms-3 mt-2 c-pointer"></i>
                )}
              </span>
              <span
                className="ms-5 mt-2 shipment-plan-id"
                onClick={() => {
                  copyToClipBoard(shipmentPlan[0]?._id);
                }}
              >
                {shipmentPlan[0]?._id}
              </span>
            </div>
          </div>
        </div>
        <div className="shipment-plan-stepper-container my-5">
          <Stepper darkMode={true} stepperData={stepperData} />
        </div>
        {shouldRenderShipmentPlan && (
          <div className="row">
            <div className="d-flex flex-wrap ms-3 mb-4">
              <div className="floating-nav">
                <input type="checkbox" id="toggle" />
                <label htmlFor="toggle" id="menu-toggle"></label>
                <ul className="menu-close-wrapper">
                  <label htmlFor="toggle" id="menu-close">
                    <i className="fa fa-times close-floating-menu"></i>
                  </label>
                  <li>
                    <div
                      className={`${
                        shouldGreyOutPaymentBtn && "sp-greyed-out-button"
                      }`}
                    >
                      <button
                        type="button"
                        className={`btn btn-success btn-generic me-3 p-0 ${
                          shipmentPlan[0].payment.paid && "disabled-button"
                        }`}
                        disabled={shipmentPlan[0].payment.paid}
                        onClick={handleValidation}
                      >
                        Pay
                      </button>
                    </div>
                  </li>
                  <li>
                    <div>
                      <button
                        type="button"
                        className={`btn btn-success btn-generic me-3 ${
                          shipmentPlan[0].payment.paid && "disabled-button"
                        }`}
                        disabled={shipmentPlan[0].payment.paid}
                        onClick={handleOnUpdate}
                      >
                        Update Shipment Plan
                      </button>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <button
                      type="button"
                      className={`btn btn-success btn-generic me-3 ${
                        shipmentPlan[0].payment.paid && "disabled-button"
                      }`}
                      disabled={shipmentPlan[0].payment.paid}
                      onClick={handleFindWarehouseRedirect}
                    >
                      Find my warehouse
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="d-flex flex-wrap dark-input mx-3 mb-4">
              <div
                className={`d-flex flex-column me-5 orderNo-container ${
                  shouldTriggerOrderNoError && "form-error"
                }`}
              >
                <label htmlFor="sp-orderNo-input">Order No</label>

                <input
                  value={orderNoInput}
                  placeholder="Order No"
                  onChange={(e) => handleOnChange(e, undefined)}
                  id="sp-orderNo-input"
                />
                <button
                  className="orderNo-btn "
                  type="button"
                  onClick={() => {
                    setOrderNoInput(uuidv4());
                    setShouldDisplayOrderNoError(false);
                  }}
                >
                  Auto
                </button>
                {shouldDisplayOrderNoError && (
                  <span className="form-error">Minimum 6 chars long</span>
                )}
              </div>

              <div className="d-flex flex-column ms-0 ms-md-3">
                <label htmlFor="sp-order-date">Order Date</label>

                <DatePicker
                  id="sp-order-date"
                  selected={orderDate}
                  minDate={new Date()}
                  onChange={(date) => {
                    setOrderDate(date);
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>

              <div
                className={`d-flex flex-column mx-0 mx-md-3 orderNo-container ${
                  shouldTriggerReceiptNoError && "form-error"
                }`}
              >
                <label htmlFor="sp-receiptNo-input">Receipt No</label>

                <input
                  value={receiptNo}
                  placeholder="Receipt No"
                  onChange={(e) => handleOnChange(e, undefined)}
                  id="sp-receiptNo-input"
                />
                {shouldDisplayReceiptNoError && (
                  <span className="form-error">Minimum 6 chars long</span>
                )}
              </div>
            </div>

            <div className="col-12 d-flex mb-2">
              {!shouldDisplayLoader && (
                <FbaAndOtherFilesUpload
                  handleOnFileSelect={handleOnFileSelect}
                  handleOnDeleteFile={handleOnDeleteFile}
                  shouldDisplayFbaTitle={shouldDisplayFbaTitle}
                  fbaFiles={fbaFiles}
                  shouldDisplaySkaTitle={shouldDisplaySkaTitle}
                  otherFiles={otherFiles}
                />
              )}
            </div>
            <div className="row">
              <div className="amazon-details-container ps-4 mb-3 accordion col-10">
                <div className="accordion-item">
                  <div className="accordion-header">
                    {" "}
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed btn-generic"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Amazon and Warehouse Details
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                    >
                      <div className="d-flex amazon-details-container mt-3">
                        {shipmentPlan[0].warehouseOwner?.businessAddress
                          ?.address && (
                          <WarehouseDetails
                            warehouseOwner={shipmentPlan[0].warehouseOwner}
                          />
                        )}
                        {shipmentPlan[0]?.amazonData?.trackShipmentUrl && (
                          <AmazonDataShipmentPlanDetails
                            amazonData={shipmentPlan[0].amazonData}
                          />
                        )}
                        {shipmentPlan[0]?.amazonData?.shipments.length !== 0 &&
                          shipmentPlan[0]?.amazonData?.shipments.map(
                            (amazonShipment, index) => (
                              <AmazonDataCard
                                shipmentPlan={shipmentPlan[0]}
                                amazonShipment={amazonShipment}
                                index={index}
                              />
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-chart-details-container mb-3 accordion col-2">
                <div className="accordion-item">
                  <div className="accordion-header">
                    {" "}
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed btn-generic"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsePaymentChart"
                        aria-expanded="true"
                        aria-controls="collapsePaymentChart"
                      >
                        Pricing Chart |
                        <span className="light-green ms-1">
                          Total: ${totalPriceCb}
                        </span>
                      </button>
                    </h2>
                    <div
                      id="collapsePaymentChart"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                    >
                      <div className="d-flex amazon-details-container mt-3">
                        <div className="ms-3 me-3 shipment-plan-payment-details">
                          <h6>Payment details</h6>
                          <ul className="p-0">
                            <li>
                              <span className="light-gray">Labelling: </span>$
                              {pricing.labelling}
                            </li>
                            <li>
                              <span className="light-gray">Box:</span> $
                              {pricing.box}
                            </li>
                            <li>
                              <span className="light-gray">Box Closing:</span> $
                              {pricing.boxClosing}
                            </li>
                            <li>
                              <span className="light-gray">
                                Bundling/Special Pack:{" "}
                              </span>
                              ${pricing.bundling}
                            </li>
                            <li>
                              <span className="light-gray">Hazmat: </span>$
                              {pricing.hazmat}
                            </li>
                            <li>
                              <span className="light-gray">
                                Processing fees:{" "}
                              </span>
                              {pricing.processingFeesPercentage}%
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {shipmentPlan[0].products.length !== 0 &&
              shipmentPlan[0].products.map((product, index) => (
                <ShipmentPlanProduct
                  product={product}
                  index={index}
                  shouldGreyOutPaymentBtn={shouldGreyOutPaymentBtn}
                  handleDisplaySpecificPlan={handleDisplaySpecificPlan}
                  onDeleteShipmentPlanProductCb={onDeleteShipmentPlanProductCb}
                  shipmentPlan={shipmentPlan[0]}
                  additionalInputsSection={additionalInputsSection}
                  areFieldsEmpty={areFieldsEmpty}
                  shipmentAdditionalInputs={shipmentAdditionalInputs}
                  handleOnChange={handleOnChange}
                  handleRadioButtonsClick={handleRadioButtonsClick}
                  showAdditionalPlanInputs={showAdditionalPlanInputs}
                  packagingLimitsError={packagingLimitsError}
                  onPayShipmentPlanCb={handleValidation}
                  onUpdateShipmentPlanCb={handleOnUpdate}
                  onFindWarehouseRedirectCb={handleFindWarehouseRedirect}
                  getTotalPriceCb={setTotalPriceCb}
                />
              ))}
          </div>
        )}
      </div>

      <Footer />
    </>
  );
};

export default ShipmentPlan;
