import "./styles.scss";

import { Footer, FullScreenLoading, HomePageNavBar } from "components";

import barsImg from "assets/images/icons/barsY.png";
import ecommCeosImg from "assets/images/ecomceos.png";
import ecommEffImg from "assets/images/ecomeffect.png";
import laptopAndBoxesImg from "assets/images/laptopAndBoxes.png";
import laptopHeaderImg from "assets/images/laptopHeader.png";
import manLaptopImg from "assets/images/manLaptop.png";
import pricingCheckImg from "assets/images/icons/pricingCheck.png";
import selectPlanImg from "assets/images/icons/selectPlan.png";
import starImg from "assets/images/icons/starY.png";
import supportImg from "assets/images/icons/supportY.png";
import { useEffect, useState } from "react";

const HomePage = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const images = [
      barsImg,
      ecommCeosImg,
      ecommEffImg,
      laptopAndBoxesImg,
      laptopHeaderImg,
      manLaptopImg,
      pricingCheckImg,
      selectPlanImg,
      starImg,
      supportImg,
    ];

    const loadImage = (src: string) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
      });
    };

    Promise.all(images.map(loadImage)).then(() => {
      setImagesLoaded(true);
    });
  }, []);

  const handlePlanSelectionClick = (plan: string) => {
    window.location.href = `/register?plan=${plan}`;
  };

  return (

    <div style={{ height: '100vh', width: '100%', }}>
      <iframe src="https://ecomeffect.com/website_d6c7b634/" width="100%" height="100%" ></iframe>
    </div>



    // <div className="container-fluid">
    //   {!imagesLoaded ? (
    //     <FullScreenLoading />
    //   ) : (
    //     // <div className="row">
    //     //   <div className="homepage text-light">
    //     //     <div className="row homepage-container ">
    //     //       <HomePageNavBar />
    //     //       <div className="row mt-5 pb-5 no-mt-mobile">
    //     //         <div className="col-md-6 col-sm-12 mt-5 pb-5 no-mt-mobile">
    //     //           <h1 className="homepage-header-caption-text mb-4">
    //     //             Launch Your{" "}
    //     //             <span className="homepage-highlight-orange">Amazon</span>{" "}
    //     //             Business
    //     //             <br />
    //     //             &amp; Start Selling Like A Pro
    //     //             <br /> Within Hours{" "}
    //     //           </h1>
    //     //           <h2 className="homepage-header-sub-caption-text">
    //     //             Here to help you scale your business no matter
    //     //             <br /> your seller level.
    //     //           </h2>
    //     //           <div className="row mt-5">
    //     //             <div className="col-sm-12">
    //     //               <button
    //     //                 type="button"
    //     //                 className="btn homepage-header-button mb-4 no-m-mobile"
    //     //               >
    //     //                 <a href="#pricing" className="nav-link">
    //     //                   I'm a new seller on amazon
    //     //                 </a>
    //     //               </button>
    //     //             </div>
    //     //           </div>
    //     //           <div className="row mt-3 mb-5 no-mb-mobile">
    //     //             <div className="col-sm-12">
    //     //               <button
    //     //                 type="button"
    //     //                 className="btn homepage-header-button mb-4 no-m-mobile"
    //     //               >
    //     //                 <a href="#pricing" className="nav-link">
    //     //                   I'm an expert seller on amazon
    //     //                 </a>
    //     //               </button>
    //     //             </div>
    //     //           </div>
    //     //         </div>
    //     //         <div className="col-md-6 col-sm-12 mt-5 no-m-mobile">
    //     //           <img
    //     //             src={laptopHeaderImg}
    //     //             className="img-fluid"
    //     //             alt="asinmice-laptop-simulation"
    //     //           />
    //     //         </div>
    //     //       </div>
    //     //     </div>
    //     //   </div>
    //     //   <section>
    //     //     <div className="container ">
    //     //       <div className="row homepage-how-it-works">
    //     //         <div className="col-10 offset-1 text-center homepage-how-it-works-title">
    //     //           <h1>How it Works</h1>
    //     //         </div>
    //     //       </div>
    //     //       <div className="row homepage-how-it-works-steps mt-5">
    //     //         <div className="col-md-3 col-sm-12 text-center my-5">
    //     //           <img
    //     //             className="mb-4"
    //     //             src={selectPlanImg}
    //     //             alt="asinmice-select-plan-icon"
    //     //           />
    //     //           <h4>
    //     //             <b>Select Plan</b>
    //     //           </h4>
    //     //           <p>Chose a plan that fits your seller needs and budget. </p>
    //     //         </div>
    //     //         <div className="col-md-3 col-sm-12 text-center my-5">
    //     //           <img
    //     //             className="mb-4"
    //     //             src={supportImg}
    //     //             alt="asinmice-select-plan-icon"
    //     //           />
    //     //           <h4>
    //     //             <b>5min Onboarding</b>
    //     //           </h4>
    //     //           <p>
    //     //             Learn how to use our tool after watching our 5min intro
    //     //             video.
    //     //           </p>
    //     //         </div>
    //     //         <div className="col-md-3 col-sm-12 text-center my-5">
    //     //           <img
    //     //             className="mb-4"
    //     //             src={starImg}
    //     //             alt="asinmice-select-plan-icon"
    //     //           />
    //     //           <h4>
    //     //             <b>Hunt Products </b>
    //     //           </h4>
    //     //           <p>
    //     //             Start scouting star products with our extension &amp;
    //     //             database.
    //     //           </p>
    //     //         </div>
    //     //         <div className="col-md-3 col-sm-12 text-center my-5">
    //     //           <img
    //     //             className="mb-4"
    //     //             src={barsImg}
    //     //             alt="asinmice-select-plan-icon"
    //     //           />
    //     //           <h4>
    //     //             <b>Accelerator Program</b>
    //     //           </h4>
    //     //           <p>
    //     //             Join our all in one stop shop to automate and scale your
    //     //             business to 7 figures.
    //     //           </p>
    //     //         </div>
    //     //       </div>
    //     //     </div>
    //     //     <hr className="homepage-separator mt-5" />
    //     //     <div className="row homepage-sponsors">
    //     //       <div className="row">
    //     //         <div className="col-12 text-center mt-5">
    //     //           <h2>Our Sponsors</h2>
    //     //         </div>
    //     //         <div className="col-lg-4 offset-lg-2 col-md-6 col-sm-12 text-center">
    //     //           <img
    //     //             src={ecommEffImg}
    //     //             className="img-fluid"
    //     //             alt="asinmice-sponsor-ecomeffect"
    //     //           />
    //     //         </div>
    //     //         <div className="col-lg-4 col-md-6 col-sm-12 pt-2 text-center">
    //     //           <img
    //     //             src={ecommCeosImg}
    //     //             className="img-fluid"
    //     //             alt="asinmice-sponsor-ecomeffect"
    //     //           />
    //     //         </div>
    //     //       </div>
    //     //     </div>
    //     //     <hr className="homepage-separator" />x{" "}
    //     //     <div className="container homepage-our-star-products">
    //     //       <div className="row">
    //     //         <div className="col-12 text-center my-5">
    //     //           <h2>Meet Our Star Produts</h2>
    //     //         </div>
    //     //       </div>
    //     //       <div className="row">
    //     //         <div className="col-md-3 col-sm-12">
    //     //           <img
    //     //             src={laptopAndBoxesImg}
    //     //             className="img-fluid"
    //     //             alt="asinmice-calculator"
    //     //           />
    //     //         </div>
    //     //         <div className="col-md-3 col-sm-12 my-5">
    //     //           <h4>
    //     //             <span className="yellow-color">Mio</span> Laboratorio
    //     //           </h4>
    //     //           <p>
    //     //             Plan and grow your Amazon store catalog and inventory with
    //     //             the help of Mio. An A.I assistant that works for you to help
    //     //             analyze your next winning product with historic charts,
    //     //             forecast, competitor analysis and the first in class
    //     //             predictive calculator.
    //     //           </p>
    //     //           <button className="btn homepage-learn-more-button">
    //     //             Learn More <span>&#10132;</span>
    //     //           </button>
    //     //         </div>
    //     //         <div className="col-md-3 my-5 d-none d-md-block ">
    //     //           <h4>
    //     //             <span className="yellow-color">Tunnel</span> Vision
    //     //           </h4>
    //     //           <p>
    //     //             Optimize your research methods and increase your product
    //     //             hunting efficiency up to 6000% with our world class Chrome
    //     //             Extension. Tunnel Vision is a fully stacked tool with all
    //     //             the key points to help you make a smart buying decision.
    //     //           </p>
    //     //           <br />
    //     //           <button className="btn homepage-learn-more-button">
    //     //             Learn More <span>&#10132;</span>
    //     //           </button>
    //     //         </div>
    //     //         <div className="col-md-3 col-sm-12">
    //     //           <img
    //     //             src={manLaptopImg}
    //     //             className="img-fluid"
    //     //             alt="asinmice-tunnel-vision"
    //     //           />
    //     //         </div>
    //     //         <div className="d-block d-md-none col-sm-12 mb-5">
    //     //           <h4>
    //     //             <span className="yellow-color">Tunnel</span> Vision
    //     //           </h4>
    //     //           <p>
    //     //             Optimize your research methods and increase your product
    //     //             hunting efficiency up to 6000% with our world class Chrome
    //     //             Extension. Tunnel Vision is a fully stacked tool with all
    //     //             the key points to help you make a smart buying decision.
    //     //           </p>
    //     //           <br />
    //     //           <button className="btn homepage-learn-more-button">
    //     //             Learn More <span>&#10132;</span>
    //     //           </button>
    //     //         </div>
    //     //       </div>
    //     //       <div className="row no-m-mobile my-5" id="pricing">
    //     //         <div className="homepage-pricing no-m-mobile my-5">
    //     //           <div
    //     //             className="homepage-pricing-plan"
    //     //             onClick={() => handlePlanSelectionClick("plan37")}
    //     //           >
    //     //             <img
    //     //               src={pricingCheckImg}
    //     //               alt="pricing check"
    //     //               className="homepage-pricing-check-icon"
    //     //             />
    //     //             <div className="homepage-pricing-price">
    //     //               <div className="card-type-text">Monthly</div>
    //     //               <div className="card-price">
    //     //                 37<span>+</span>
    //     //               </div>
    //     //               <span className="card-subtext">TAXES/PER MONTH</span>
    //     //             </div>
    //     //             <div className="homepage-pricing-features">
    //     //               <ul>
    //     //                 <li>Copy and search</li>
    //     //                 <li>Selleable report</li>
    //     //                 <li>Hazmat report</li>
    //     //                 <li>2500 monthly sale reports</li>
    //     //                 <li>Email priority support</li>
    //     //                 <li>&nbsp;</li>
    //     //               </ul>
    //     //               <button className="btn homepage-pricing-button">
    //     //                 Start Your Free Trial
    //     //               </button>
    //     //             </div>
    //     //           </div>
    //     //           <div
    //     //             className="homepage-pricing-plan"
    //     //             onClick={() => handlePlanSelectionClick("plan287")}
    //     //           >
    //     //             <img
    //     //               src={pricingCheckImg}
    //     //               alt="pricing check"
    //     //               className="homepage-pricing-check-icon"
    //     //             />
    //     //             <div className="homepage-pricing-price">
    //     //               <div className="card-type-text">Annual</div>
    //     //               <div className="card-price">
    //     //                 287<span>+</span>
    //     //               </div>
    //     //               <span className="card-subtext">TAXES/PER MONTH</span>
    //     //             </div>
    //     //             <div className="homepage-pricing-features">
    //     //               <ul>
    //     //                 <li>Copy and search</li>
    //     //                 <li>Selleable report</li>
    //     //                 <li>Hazmat report</li>
    //     //                 <li>5000 monthly sale reports</li>
    //     //                 <li>Email priority support</li>
    //     //                 <li>Dr. Mice sellers academy</li>
    //     //               </ul>
    //     //               <button className="btn homepage-pricing-button">
    //     //                 Start Your Free Trial
    //     //               </button>
    //     //             </div>
    //     //           </div>
    //     //         </div>
    //     //       </div>
    //     //       <div className="row homepage-download-title-section-bottom mt-5">
    //     //         <div className="col-8 offset-2 text-center homepage-download-title-section-bottom-title mt-5">
    //     //           <h1>
    //     //             <a
    //     //               href="https://chromewebstore.google.com/detail/unielogics/bagcekacfgdabdkhamefiohigjiepjpb"
    //     //               target="_blank"
    //     //               rel="noreferrer"
    //     //             >
    //     //               DOWNLOAD
    //     //             </a>
    //     //           </h1>
    //     //         </div>
    //     //       </div>
    //     //     </div>
    //     //   </section>
    //     //   <Footer />
    //     // </div>

    //     // <div style={{ height: '100vh', width: '100%' }}>
    //     //   <iframe src="https://unielogics.appssols.com/" width="100%" height="100%" ></iframe>
    //     // </div>
    //   )}
    // </div>
  );
};
export default HomePage;
