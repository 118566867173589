import "./styles.scss";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";

import { HomePageNavBar } from "components";
import { IRedirectToStripe, IRegisterFormType } from "./interfaces";
import { postCheckoutRequest, postRegisterRequest } from "./api";

import passwordShow from "assets/images/icons/showPass.png";
import passwordHide from "assets/images/icons/hidePass.png";
import logo from "assets/images/logoBig.png";
import { getQueryParameterByName, STRIPE_KEYS, plans, QUERY_KEYS } from "utils";

const Register = () => {
  const [showPasswordIcon, setShowPasswordIcon] = useState({
    password: true,
    cpassword: true,
  });
  const queryClient = useQueryClient();
  const validationScheme = Yup.object().shape({
    firstName: Yup.string().required("First Name cannot be empty"),
    lastName: Yup.string().required("Last Name cannot be empty"),
    email: Yup.string().required("Email cannot be empty"),
    phoneNumber: Yup.string().required("Phone Number cannot be empty"),
    password: Yup.string()
      .required("Password field cannot be empty")
      .min(3, "Password must be at least 3 characters long"),
    confirmPassword: Yup.string()
      .required("Confirm Password field cannot be empty")
      .oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password do not match"
      ),
  });
  const formOptions = { resolver: yupResolver(validationScheme) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegisterFormType>(formOptions);

  useEffect(() => {
    (Object.keys(errors) as (keyof typeof errors)[]).forEach((key, index) => {
      toast.error(errors[key]?.message);
    });
  }, [errors]);

  const fetchStripeAndRedirectToCheckout = async ({
    sessionId,
  }: IRedirectToStripe) => {
    const publishableKey = STRIPE_KEYS.STRIPE_PUBLISHABLEKEY;
    const stripe = await loadStripe(publishableKey);
    stripe?.redirectToCheckout({ sessionId });
  };

  const { mutate: postRegisterMutate, isLoading: isLoadingRegister } =
    useMutation(postRegisterRequest, {
      onSuccess: (data: any) => {
        const customerID = data.user?.customerID;
        const product = getQueryParameterByName("plan") ?? plans.PLAN_37;

        postCheckoutMutate({
          customerID,
          product,
        });
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ?? "There was an error signing you in."
        );
        return;
      },
    });

  const { mutate: postCheckoutMutate, isLoading: isLoadingCheckout } =
    useMutation(postCheckoutRequest, {
      onSuccess: (data) => {
        if (data.status === "success") {
          const { sessionId } = data;
          fetchStripeAndRedirectToCheckout({ sessionId });
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ?? "There was an error signing you in."
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(QUERY_KEYS.POST_CHECKOUT);
      },
    });

  // @ts-ignore
  //To investigate rewardfull integration as sometimes it crashes
  // const referral = Rewardful.referral;
  const referral = "";

  const onRegisterSubmit = async ({
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
  }: IRegisterFormType) => {
    const username = email;
    const selectedPlan = getQueryParameterByName("plan");

    if (selectedPlan !== plans.PLAN_37 && selectedPlan !== plans.PLAN_287) {
      toast.error(
        "You have to select a plan before registering. Please go to home page and select the desired plan."
      );
      return;
    }
    postRegisterMutate({
      firstName,
      lastName,
      phoneNumber,
      email,
      username,
      password,
      referral,
    });
  };

  return (
    <div className="container-fluid register-form-container">
      <div className="row">
        <form
          autoComplete="nope"
          className="register-form"
          onSubmit={handleSubmit(onRegisterSubmit)}
        >
          <div className="register-form-center-alignement">
            <div className="register-form-logo text-center">
              <Link to="/">
                <img src={logo} alt="asinmice logo" />
              </Link>
            </div>
            <h1 className="register-form-title text-center mb-3">
              Register Account
            </h1>
            <div
              className={`form-group mb-2 ${
                errors.firstName && "register-form-error"
              }`}
            >
              <label
                className="register-form-label"
                htmlFor="register-input-fname"
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control register-form-input register-form-input-fname"
                aria-describedby="firstName"
                id="register-input-name"
                {...register("firstName")}
                autoComplete="none"
              />
            </div>
            <div
              className={`form-group mb-2 ${
                errors.lastName && "register-form-error"
              }`}
            >
              <label
                className="register-form-label"
                htmlFor="register-input-lname"
              >
                Last Name
              </label>
              <input
                type="text"
                className="form-control register-form-input register-form-input-lname"
                aria-describedby="lastName"
                id="register-input-name"
                {...register("lastName")}
                autoComplete="none"
              />
            </div>
            <div
              className={`form-group mb-2 ${
                errors.phoneNumber && "register-form-error"
              }`}
            >
              <label
                className="register-form-label"
                htmlFor="register-input-pnumber"
              >
                Phone Number
              </label>
              <input
                type="text"
                className="form-control register-form-input register-form-input-pnumber"
                aria-describedby="phoneNumber"
                id="register-input-pnumber"
                {...register("phoneNumber")}
                autoComplete="none"
              />
            </div>
            <div
              className={`form-group mb-2 ${
                errors.email && "register-form-error"
              }`}
            >
              <label
                className="register-form-label"
                htmlFor="register-input-email"
              >
                Email
              </label>
              <input
                type="email"
                className="form-control register-form-input register-form-input-email"
                aria-describedby="email"
                id="register-input-email"
                {...register("email")}
                autoComplete="none"
              />
            </div>
            <div
              className={`form-group register-form-password-container mb-3 ${
                errors.password && "register-form-error"
              }`}
            >
              <label
                className="register-form-label"
                htmlFor="register-input-password"
              >
                Password
              </label>
              <span className="register-form-input-password-container">
                <input
                  type={`${showPasswordIcon.password ? "password" : "text"}`}
                  className="form-control register-form-input register-form-input-password"
                  aria-describedby="password"
                  id="register-input-password"
                  {...register("password")}
                  autoComplete="none"
                />
                {showPasswordIcon.password && (
                  <img
                    src={passwordShow}
                    onClick={() =>
                      setShowPasswordIcon({
                        ...showPasswordIcon,
                        password: false,
                      })
                    }
                    alt="show password"
                  />
                )}
                {!showPasswordIcon.password && (
                  <img
                    src={passwordHide}
                    onClick={() =>
                      setShowPasswordIcon({
                        ...showPasswordIcon,
                        password: true,
                      })
                    }
                    alt="hide password"
                  />
                )}
              </span>
            </div>
            <div
              className={`form-group register-form-password-container mb-4 ${
                errors.confirmPassword && "register-form-error"
              }`}
            >
              <label
                className="register-form-label"
                htmlFor="register-input-cpassword"
              >
                Confirm Password
              </label>
              <span className="register-form-input-password-container">
                <input
                  type={`${showPasswordIcon.cpassword ? "password" : "text"}`}
                  className="form-control register-form-input register-form-input-cpassword"
                  aria-describedby="cpassword"
                  id="register-input-cpassword"
                  {...register("confirmPassword")}
                  autoComplete="none"
                />
                {showPasswordIcon.cpassword && (
                  <img
                    src={passwordShow}
                    onClick={() =>
                      setShowPasswordIcon({
                        ...showPasswordIcon,
                        cpassword: false,
                      })
                    }
                    alt="show password"
                  />
                )}
                {!showPasswordIcon.cpassword && (
                  <img
                    src={passwordHide}
                    onClick={() =>
                      setShowPasswordIcon({
                        ...showPasswordIcon,
                        cpassword: true,
                      })
                    }
                    alt="hide password"
                  />
                )}
              </span>
            </div>
            <div className="form-group register-form-relative-flex mt-5 mb-3">
              <button type="submit" className="btn register-form-submit-button">
                Register
              </button>
              {(isLoadingRegister || isLoadingCheckout) && (
                <div
                  className="spinner-border register-form-submit-loading"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </div>
            <div className="form-group register-form-got-to-login-link my-3 text-center">
              <Link to="/login">Go to Login</Link>
            </div>
            <div className="form-group register-form-footer-info">
              <p>
                Once the registration process is complete, a confirmation email
                will be sent to you.
              </p>
            </div>
          </div>
        </form>
        <div className="register-right-side d-flex flex-column">
          <div className="navigation">
            <HomePageNavBar active="products" />
          </div>
          <div className="d-flex h-100 align-items-center justify-content-center">
            <h1>
              Scale Your Amazon Business
              <br />
              <span>Get started in 60 seconds</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
