import { indexOf } from "lodash";
import { IAmazonDataCard } from "./interfaces";

const AmazonDataCard = ({
  shipmentPlan,
  amazonShipment,
  index,
}: IAmazonDataCard) => {
  return (
    <div
      className=" amazon-shipment-plan me-3 mb-3"
      key={indexOf(shipmentPlan?.amazonData?.shipments, amazonShipment)}
    >
      <h6>Shipment #{index + 1}</h6>
      <div>
        Amazon Refererence ID:{" "}
        <span className="amazon-data-value">
          {amazonShipment.amazonReferenceId}
        </span>
      </div>
      <div>
        Ship From:{" "}
        <span className="amazon-data-value">{amazonShipment.shipFrom}</span>
      </div>
      <div>
        Ship To:{" "}
        <span className="amazon-data-value">{amazonShipment.shipTo}</span>
      </div>
      <div>
        Shipment ID:{" "}
        <span className="amazon-data-value">{amazonShipment.shipmentId}</span>
      </div>
      <div>
        Shipment Name:{" "}
        <span className="amazon-data-value">{amazonShipment.shipmentName}</span>
      </div>
      <div>
        Number of SKUs:{" "}
        <span className="amazon-data-value">{amazonShipment.skus}</span>
      </div>
      <div>
        <span className="me-2">Images:</span>
        {amazonShipment.thumbnailUrls.map((thumbnail: any) => (
          <span
            className="me-3"
            key={indexOf(amazonShipment.thumbnailUrls, thumbnail)}
          >
            <img src={thumbnail.url} alt="sku-img" />
          </span>
        ))}
      </div>
      <div className="d-flex flex-column">
        <span>Contents: </span>
        <span className="ms-3">
          Boxes:{" "}
          <span className="amazon-data-value">
            {amazonShipment.contents?.boxes}
          </span>
        </span>
        <span className="ms-3">
          SKUs:{" "}
          <span className="amazon-data-value">
            {amazonShipment.contents?.skus}
          </span>
        </span>
        <span className="ms-3">
          Units:{" "}
          <span className="amazon-data-value">
            {amazonShipment.contents?.units}
          </span>
        </span>
        <span className="ms-3">
          Weight:{" "}
          <span className="amazon-data-value">
            {amazonShipment.contents?.weight}
          </span>
        </span>
      </div>
    </div>
  );
};

export default AmazonDataCard;
