import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { IBoundsProps } from "./interfaces";

const group = L.featureGroup();

const Bounds: React.FC<IBoundsProps> = ({ coords }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    group.clearLayers();

    coords.forEach((marker) => group.addLayer(L.marker(marker)));

    map.fitBounds(group.getBounds(), {
      padding: [20, 20],
    });
  }, [map, coords]);

  return null;
};

export default Bounds;
