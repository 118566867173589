import { IAmazonDataShipmentPlanDetails } from "./interfaces";

const AmazonDataShipmentPlanDetails = ({
  amazonData,
}: IAmazonDataShipmentPlanDetails) => {
  return (
    <div className="d-flex flex-column align-items-md-start align-items-sm-center amazon-shipment-plan-details me-5">
      <h6>Amazon Shipment Plan Details:</h6>
      <div>
        Ship date:{" "}
        <span className="amazon-data-value">{amazonData.shipDate}</span>
      </div>
      <div>
        Ship from:{" "}
        <span className="amazon-data-value">{amazonData.shipFrom}</span>
      </div>
      <div>
        Tracking url:{" "}
        <a href={amazonData.trackShipmentUrl}>Click to track your shipment</a>
      </div>
      <div>
        Track Shipment Value:{" "}
        <span className="amazon-data-value">
          {amazonData.trackShipmentValue}
        </span>
      </div>
      <div>
        Track Shipment Value:{" "}
        <span className="amazon-data-value">
          {amazonData.trackShipmentValue}
        </span>
      </div>
      <div>
        Workflow ID:{" "}
        <span className="amazon-data-value">{amazonData.workFlowId}</span>
      </div>
      <div className="d-flex flex-column">
        <span>Fees: </span>
        <span className="ms-3 amazon-data-value">
          Placement fees: {amazonData.placementFees}
        </span>
        <span className="ms-3 amazon-data-value">
          Prep label fees: {amazonData.prepLabelFees}
        </span>
        <span className="ms-3 amazon-data-value">
          Shipping Charges: {amazonData.shippingCharges}
        </span>
        <span className="ms-3 amazon-data-value">
          Fee summary: {amazonData.feeSummary}
        </span>
      </div>
    </div>
  );
};

export default AmazonDataShipmentPlanDetails;
