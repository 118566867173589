import { Footer, Loading, Modal, PageTitle, SidebarMenu } from "components";
import {
  QUERY_KEYS,
  UserContext,
  ellipsis,
  pageRoutes,
  shipmentTableHeaders,
} from "utils";
import { useMutation, useQuery } from "react-query";
import { deleteShipmentPlanRequest, getShipmentPlanRequest } from "./api";
import { IContextUser } from "common/interfaces";
import { useContext, useEffect, useState } from "react";
import { IDeleteShipmentPlanResponse, IShipmentPlan } from "./interfaces";
import React from "react";
import { shipmentProductsHeaders } from "utils/configs";

import "./styles.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ShipmentPlan = () => {
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const [shipmentPlans, setShipmentPlans] = useState<IShipmentPlan[]>([]);
  const [expandedShipmentPlan, setExpandedShipmentPlan] = useState<
    string | null
  >(null);
  const [showDeleteShipmentPlanModal, setShowDeleteSupplierModal] =
    useState(false);
  const [shipmentPlanIdToBeDeleted, setShipmentPlanIdToBeDeleted] =
    useState("");
  const navigate = useNavigate();

  const { data, isLoading: isLoadingShipmentPlans } = useQuery(
    QUERY_KEYS.GET_SUPPLIERS,
    () => getShipmentPlanRequest(email)
  );

  const { mutate: deleteShipmentPlan, isLoading: isLoadingDeleteShipmentPlan } =
    useMutation(deleteShipmentPlanRequest, {
      onSuccess: (data: IDeleteShipmentPlanResponse) => {
        if (data.status === "success") {
          toast.success("Successfully deleted the selected Shipment Plan.");
          setShipmentPlans(data?.response?.shipmentPlans);
        } else {
          toast.error("There was an error deleting your Shipment Plan.");
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error deleting your Shipment Plan."
        );
        return;
      },
    });

  const onDeleteShipmentPlanClickCb = (
    e: React.SyntheticEvent<EventTarget>,
    _id: string
  ) => {
    e.stopPropagation();
    setShowDeleteSupplierModal(true);
    setShipmentPlanIdToBeDeleted(_id);
  };

  const onModalConfirmDeleteShipmentPlanClick = () => {
    setShowDeleteSupplierModal(false);
    deleteShipmentPlan({ email, _id: shipmentPlanIdToBeDeleted });
  };

  const goToShipmentPlan = (id: string) => {
    navigate(`${pageRoutes.SHIPMENT_PLAN_ID}?id=${id}`);
  };

  useEffect(() => {
    if (data?.response?.shipmentPlans) {
      setShipmentPlans(data?.response?.shipmentPlans);
    }
  }, [data]);

  return (
    <>
      <div className="content">
        <div className="container-fluid shipment-plans">
          <div className="sidebar">
            <SidebarMenu active="shipment-plans" sidebarOnly={true} />
          </div>
          <PageTitle pageTitle="Shipment Plans" />
          {shipmentPlans.length > 0 && (
            <p className="info-text">
              *Click on a row to see the products from that shipment plan
            </p>
          )}
          {showDeleteShipmentPlanModal && (
            <Modal
              okButtonText="Delete"
              modalTitle="Are you sure you want to delete this Shipment Plan?"
              modalTextContent="Please be advised that selecting 'Delete' will result in the permanent removal of the Shipment Plan."
              okModalClick={onModalConfirmDeleteShipmentPlanClick}
              cancelModalClick={() => setShowDeleteSupplierModal(false)}
            />
          )}
          {isLoadingShipmentPlans || isLoadingDeleteShipmentPlan ? (
            <div className="row">
              <div className="col-12 d-flex justify-content-center loading-wrapper">
                <Loading />
              </div>
            </div>
          ) : (
            shipmentPlans.length === 0 && (
              <div className="no-items text-center">
                <h3>You have no Shipment Plans!</h3>
                <p>Start creating your Shipment plans now.</p>
                <span className="no-items-icon-wrapper">
                  <i className="fas fa-paper-plane"></i>
                  {/* <img src={emptyFolderPng} alt="" /> */}
                </span>
              </div>
            )
          )}
          {!isLoadingShipmentPlans &&
            !isLoadingDeleteShipmentPlan &&
            shipmentPlans.length > 0 && (
              <div className="row">
                <div className="col-sm">
                  <div className="table-responsive max-h-78vh">
                    <table className="table table-sm">
                      <thead className="sticky-header">
                        <tr>
                          {shipmentTableHeaders.map((header) => (
                            <th className="text-center" key={header.name}>
                              {header.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {shipmentPlans &&
                          shipmentPlans.map((shipmentPlan, index) => (
                            <React.Fragment key={shipmentPlan._id}>
                              <tr
                                onClick={() =>
                                  setExpandedShipmentPlan(
                                    expandedShipmentPlan === shipmentPlan._id
                                      ? null
                                      : shipmentPlan._id
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td className="align-middle text-center white-space-nowrap">
                                  {index + 1}
                                </td>
                                <td className="align-middle text-center white-space-nowrap">
                                  {shipmentPlan.dateAdded || " "}
                                </td>
                                <td className="align-middle text-center white-space-nowrap">
                                  {shipmentPlan.shipmentTitle || " "}
                                </td>
                                <td className="align-middle text-center white-space-nowrap">
                                  {shipmentPlan.products.length}
                                </td>
                                <td className="align-middle text-center white-space-nowrap">
                                  <span
                                    className={`badge badge-${
                                      shipmentPlan.status.toLocaleLowerCase() ===
                                        "added" ||
                                      shipmentPlan.status.toLocaleLowerCase() ===
                                        "in progress"
                                        ? "warning"
                                        : shipmentPlan.status.toLocaleLowerCase() ===
                                          "shipped"
                                        ? "success"
                                        : "danger"
                                    }`}
                                  >
                                    {shipmentPlan.status}
                                  </span>
                                </td>
                                <td className="align-middle text-center white-space-nowrap">
                                  <span
                                    className="icon-wrapper me-3"
                                    onClick={() =>
                                      goToShipmentPlan(shipmentPlan._id)
                                    }
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span
                                    className="icon-wrapper me-3"
                                    onClick={(e) =>
                                      onDeleteShipmentPlanClickCb(
                                        e,
                                        shipmentPlan._id
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash-o text-danger"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </td>
                              </tr>
                              {expandedShipmentPlan === shipmentPlan._id && (
                                <tr className="no-interaction">
                                  <td colSpan={6}>
                                    <table className="table table-sm shipment-plans-products-table">
                                      <thead className="sticky-header">
                                        <tr className="no-interaction">
                                          {shipmentProductsHeaders.map(
                                            (header) => (
                                              <th
                                                className="text-center"
                                                key={header.name}
                                              >
                                                {header.name}
                                              </th>
                                            )
                                          )}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {shipmentPlan.products.map(
                                          (product) => (
                                            <tr
                                              key={`${shipmentPlan._id}-products`}
                                              className="product-details"
                                            >
                                              <td className="align-middle text-center white-space-nowrap">
                                                <a
                                                  href={`https://www.amazon.com/dp/${product.asin}`}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {product.asin}
                                                </a>
                                              </td>
                                              <td className="align-middle text-center white-space-nowrap">
                                                <img
                                                  src={product.imageUrl}
                                                  alt="product-thumb"
                                                />
                                              </td>
                                              <td className="align-middle text-center white-space-nowrap">
                                                {ellipsis(product.title, 100)}
                                              </td>
                                              <td className="align-middle text-center white-space-nowrap">
                                                {product.unitsPerBox}
                                              </td>
                                              <td className="align-middle text-center white-space-nowrap">
                                                {`${product.boxWidth}x${product.boxHeight}x${product.boxLength}`}
                                              </td>
                                              <td className="align-middle text-center white-space-nowrap">
                                                ${product.amazonPrice}
                                              </td>
                                              <td className="align-middle text-center white-space-nowrap">
                                                {product.supplier.supplierName}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ShipmentPlan;
