import { isEmpty } from "lodash";
import { ellipsis } from "utils";
import { IShipmentPlanProductComponent } from "./interfaces";
import { pricing } from "pages/shipmentPlan/pricing";
import { useEffect, useState } from "react";

import "./styles.scss";

const ShipmentPlanProduct = ({
  product,
  index,
  shipmentPlan,
  handleDisplaySpecificPlan,
  onDeleteShipmentPlanProductCb,
  onPayShipmentPlanCb,
  onUpdateShipmentPlanCb,
  onFindWarehouseRedirectCb,
  additionalInputsSection,
  areFieldsEmpty,
  shipmentAdditionalInputs,
  handleOnChange,
  handleRadioButtonsClick,
  showAdditionalPlanInputs,
  packagingLimitsError,
  shouldGreyOutPaymentBtn,
  getTotalPriceCb,
}: IShipmentPlanProductComponent) => {
  const [showQuantityWarning, setShowQuantityWarning] = useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    // Calculate the newUnitsPerBox and determine if a warning should be shown
    const unitsPerBox = shipmentAdditionalInputs[`sp-unitsPerBox-${index}`];
    const shrinkWrap = shipmentAdditionalInputs[`sp-shrink-wrap-${index}`];
    const specialPackaging =
      shipmentAdditionalInputs[`sp-special-packaging-${index}`];

    let newUnitsPerBox = 0;
    let bundleCount = shrinkWrap.answer
      ? shrinkWrap.amount
      : specialPackaging.answer
      ? specialPackaging.amount
      : 0;

    if (bundleCount !== 0) {
      newUnitsPerBox = unitsPerBox / bundleCount;

      const shouldShowWarning = !(newUnitsPerBox % 1 === 0);
      setShowQuantityWarning({
        [index]: shouldShowWarning,
      });
    }
  }, [shipmentAdditionalInputs, index]);

  useEffect(() => {
    getTotalPrice();
  });

  const getProductPrice = (index: number, isHazmat: boolean) => {
    let pricePerItem = 0;
    const unitsPerBox = shipmentAdditionalInputs[`sp-unitsPerBox-${index}`];
    const shrinkWrap = shipmentAdditionalInputs[`sp-shrink-wrap-${index}`];
    const specialPackaging =
      shipmentAdditionalInputs[`sp-special-packaging-${index}`];
    const boxes = shipmentAdditionalInputs[`sp-boxes-${index}`];
    const totalUnits = parseInt(unitsPerBox) * parseInt(boxes);
    const boxClosingPrice = pricing.boxClosing * boxes;
    let labellingPrice = 0;
    let hazmatPricing = 0;
    let bundleCount = shrinkWrap.answer
      ? shrinkWrap.amount
      : specialPackaging.answer
      ? specialPackaging.amount
      : 0;
    let bundlingPrice = 0;
    let newUnitsPerBox = 0;
    let integerPartOfNewUnitsPerBox = 0;
    let decimalPartOfNewUnitsPerBox = 0;
    let unitsWithOriginalBox = 0;
    let unitsWithNoBox = 0;
    let possibleBundleBox = 0;
    let additionalBoxesPrice = 0;
    let newTotalUnitsForBundling = 0;

    if (bundleCount === 0 && (shrinkWrap.answer || specialPackaging.answer)) {
      bundleCount = NaN;
    }
    if (isHazmat) {
      hazmatPricing = totalUnits * pricing.hazmat;
    }

    if (bundleCount !== 0) {
      // HERE WE WILL CALCULATE IF SPECIAL PACKAGING OR SHRINK WRAP IS YES
      newUnitsPerBox = unitsPerBox / bundleCount;

      // If we have decimals we need to add costs for box.
      if (!(newUnitsPerBox % 1 === 0)) {
        integerPartOfNewUnitsPerBox = Math.floor(newUnitsPerBox);
        decimalPartOfNewUnitsPerBox =
          newUnitsPerBox - integerPartOfNewUnitsPerBox;
        unitsWithOriginalBox =
          bundleCount * boxes * integerPartOfNewUnitsPerBox;
        unitsWithNoBox = totalUnits - unitsWithOriginalBox;
        possibleBundleBox = unitsWithNoBox / bundleCount;
        additionalBoxesPrice = Math.floor(possibleBundleBox) * pricing.box;
        additionalBoxesPrice = Number(additionalBoxesPrice.toFixed(1));

        console.log("newUnitsPerBox has decimals");
        console.log("integerPartOfNewUnitsPerBox", integerPartOfNewUnitsPerBox);
        console.log("decimalPartOfNewUnitsPerBox", decimalPartOfNewUnitsPerBox);
        console.log("unitsWithOriginalBox", unitsWithOriginalBox);
        console.log("unitsWithNoBox", unitsWithNoBox);
        console.log("possibleBundleBox", possibleBundleBox);
        console.log("additionalBoxesPrice", additionalBoxesPrice);
      }

      newTotalUnitsForBundling = totalUnits / parseInt(bundleCount);
      bundlingPrice = Number(
        (pricing.bundling * Math.floor(newTotalUnitsForBundling)).toFixed(2)
      );
    } else {
      labellingPrice = pricing.labelling * totalUnits;
    }

    const partialTotal =
      boxClosingPrice +
      labellingPrice +
      hazmatPricing +
      bundlingPrice +
      parseInt(additionalBoxesPrice.toFixed(2));
    // Calculate processing fees as a percentage of partialTotal
    const processingFeesPercentage = pricing.processingFeesPercentage / 100; // Convert percentage to decimal
    const processingFees = partialTotal * processingFeesPercentage;

    pricePerItem = partialTotal + Number(processingFees.toFixed(2));

    return Number(pricePerItem.toFixed(2));
  };

  const getTotalPrice = () => {
    const totalCost = shipmentPlan.products.reduce((total, product, index) => {
      const productPrice = getProductPrice(index, product.isHazmat) || 0;
      return total + productPrice;
    }, 0);
    getTotalPriceCb(totalCost.toFixed(2));
    return totalCost.toFixed(2);
  };

  return (
    <div className="d-flex flex-column sp-container" key={index}>
      <div
        className="d-flex justify-content-between align-items-center"
        key={product._id}
      >
        <div className="sp-item ms-3 mt-3 mb-1 p-1">
          <div className="d-flex align-items-center first-row">
            <img className="me-2" src={product.imageUrl} alt="product-thumb" />
            <div>
              <p className="description mb-1 me-3">
                {ellipsis(product.title, 50)}
              </p>
              <p className="m-0 mb-1">
                Supplier: {product.supplier.supplierName}
              </p>
            </div>
          </div>

          <div className="sp-second-row d-flex mt-1">
            <span>ASIN:</span>
            <a
              href={`https://www.amazon.com/dp/${product.asin}`}
              target="_blank"
              rel="noreferrer"
              className="ms-1 me-2"
            >
              {product.asin}
            </a>
            <span className="me-2">
              QTY:{" "}
              <span className="white">
                {product.unitsPerBox && product.boxes
                  ? parseInt(product.unitsPerBox) * parseInt(product.boxes)
                  : "-"}
              </span>
            </span>
            <span className="me-1">Hazmat:</span>
            <span>
              <span className="white">
                {product.hasOwnProperty("isHazmat")
                  ? !product.isHazmat
                    ? "NO"
                    : "YES"
                  : "-"}
              </span>
            </span>
          </div>
        </div>

        <div className="d-flex align-items-center me-3">
          <p className="price m-0 mb-1 me-3">
            Estimated Cost:{" "}
            <span className="estimated-cost">
              ${getProductPrice(index, product.isHazmat) || "-"}
            </span>
          </p>
          <button
            className="sp-expand-btn me-2"
            onClick={() => handleDisplaySpecificPlan(product._id)}
          >
            <i className="fa fa-arrows-alt"></i>
          </button>

          <span
            className="icon-wrapper me-2"
            onClick={() =>
              onDeleteShipmentPlanProductCb(shipmentPlan._id, product._id)
            }
          >
            <i
              className="fa fa-trash-o trash-icon text-danger "
              aria-hidden="true"
            ></i>
          </span>
        </div>
      </div>
      {additionalInputsSection[`product-${product._id}`] && (
        <div className="d-flex flex-column align-items-center sp-inputs-container">
          <div className="d-flex flex-wrap justify-content-center sp-inputs">
            <div className="d-flex flex-wrap sp-inputs-first-row  me-5">
              <div className="d-flex flex-column sp-dimensions mb-3 dark-input">
                <div
                  className={`d-flex sp-unitsPerBox flex-row justify-content-center dark-input ${
                    areFieldsEmpty &&
                    isEmpty(
                      shipmentAdditionalInputs[`sp-unitsPerBox-${index}`]
                    ) &&
                    "form-error"
                  }`}
                >
                  <input
                    id={`sp-unitsPerBox-${index}`}
                    onChange={(e) => handleOnChange(e, index)}
                    value={
                      shipmentAdditionalInputs[
                        `sp-unitsPerBox-${index}`
                      ] as string
                    }
                    type="text"
                    placeholder="Units Per Box"
                    autoComplete="none"
                  />
                </div>

                <div
                  className={`d-flex sp-fnsku flex-row justify-content-center dark-input ${
                    areFieldsEmpty &&
                    isEmpty(shipmentAdditionalInputs[`sp-fnsku-${index}`]) &&
                    "form-error"
                  }`}
                >
                  <input
                    id={`sp-fnsku-${index}`}
                    onChange={(e) => handleOnChange(e, index)}
                    value={
                      shipmentAdditionalInputs[`sp-fnsku-${index}`] as string
                    }
                    type="text"
                    placeholder="FNSKU"
                    autoComplete="none"
                  />
                </div>
              </div>

              <div className="d-flex  dark-input sp-dimensions">
                <div>
                  <div
                    className={`${
                      areFieldsEmpty &&
                      isEmpty(shipmentAdditionalInputs[`sp-width-${index}`]) &&
                      "form-error"
                    }`}
                  >
                    <input
                      id={`sp-width-${index}`}
                      value={
                        shipmentAdditionalInputs[`sp-width-${index}`] as string
                      }
                      onChange={(e) => handleOnChange(e, index)}
                      type="text"
                      placeholder="W"
                      autoComplete="none"
                    />
                  </div>

                  <div
                    className={`${
                      areFieldsEmpty &&
                      isEmpty(shipmentAdditionalInputs[`sp-height-${index}`]) &&
                      "form-error"
                    }`}
                  >
                    <input
                      id={`sp-height-${index}`}
                      onChange={(e) => handleOnChange(e, index)}
                      value={
                        shipmentAdditionalInputs[`sp-height-${index}`] as string
                      }
                      type="text"
                      placeholder="H"
                      autoComplete="none"
                    />
                  </div>

                  <div
                    className={`${
                      areFieldsEmpty &&
                      isEmpty(shipmentAdditionalInputs[`sp-length-${index}`]) &&
                      "form-error"
                    }`}
                  >
                    <input
                      id={`sp-length-${index}`}
                      onChange={(e) => handleOnChange(e, index)}
                      value={
                        shipmentAdditionalInputs[`sp-length-${index}`] as string
                      }
                      type="text"
                      placeholder="L"
                      autoComplete="none"
                    />
                  </div>
                </div>
                <div>
                  <div
                    className={`d-flex sp-boxes flex-row justify-content-center dark-input ${
                      areFieldsEmpty &&
                      isEmpty(shipmentAdditionalInputs[`sp-boxes-${index}`]) &&
                      "form-error"
                    }`}
                  >
                    <input
                      id={`sp-boxes-${index}`}
                      onChange={(e) => handleOnChange(e, index)}
                      value={
                        shipmentAdditionalInputs[`sp-boxes-${index}`] as string
                      }
                      type="text"
                      placeholder="BOXES"
                      autoComplete="none"
                    />
                  </div>

                  <div
                    className={`d-flex sp-weightPerBox flex-row justify-content-center dark-input  ${
                      areFieldsEmpty &&
                      isEmpty(
                        shipmentAdditionalInputs[`sp-weightPerBox-${index}`]
                      ) &&
                      "form-error"
                    }`}
                  >
                    <input
                      id={`sp-weightPerBox-${index}`}
                      onChange={(e) => handleOnChange(e, index)}
                      value={
                        shipmentAdditionalInputs[
                          `sp-weightPerBox-${index}`
                        ] as string
                      }
                      type="text"
                      placeholder="W. PER BOX"
                      autoComplete="none"
                    />
                  </div>

                  <div
                    className={`d-flex sp-upc flex-row justify-content-center dark-input ${
                      areFieldsEmpty &&
                      isEmpty(shipmentAdditionalInputs[`sp-upc-${index}`]) &&
                      "form-error"
                    }`}
                  >
                    <input
                      id={`sp-upc-${index}`}
                      onChange={(e) => handleOnChange(e, index)}
                      value={
                        shipmentAdditionalInputs[`sp-upc-${index}`] as string
                      }
                      type="text"
                      placeholder="UPC"
                      autoComplete="none"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column">
              <div className="d-flex">
                <div>
                  <div className="d-flex flex-column me-5">
                    <h5>Hazmat?</h5>

                    <div className="form-check">
                      <input
                        disabled
                        checked={product.isHazmat}
                        className="form-check-input"
                        type="radio"
                        name={`decideHazmat-${index}`}
                        id="decideHazmat"
                        value="Yes"
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check radio-container">
                      <input
                        disabled
                        checked={!product.isHazmat}
                        name={`decideHazmat-${index}`}
                        className="form-check-input"
                        type="radio"
                        id="decideHazmat-no"
                        autoComplete="none"
                        value="No"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="decideHazmat"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="me-5">
                  <div className="d-flex flex-column">
                    <h5>Shrink Wrap?</h5>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`decideShrinkWrap-yes-${index}`}
                        name={`decideShrinkWrap-${index}`}
                        value="Yes"
                        onChange={() =>
                          handleRadioButtonsClick(
                            `sp-shrink-wrap-${index}`,
                            index
                          )
                        }
                        checked={
                          showAdditionalPlanInputs[`sp-shrink-wrap-${index}`]
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`decideShrinkWrap-yes-${index}`}
                      >
                        Yes
                      </label>
                    </div>

                    <div className="form-check radio-container">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`decideShrinkWrap-${index}`}
                        id={`decideShrinkWrap-no-${index}`}
                        autoComplete="none"
                        value="No"
                        onChange={() =>
                          handleRadioButtonsClick(
                            `sp-shrink-wrap-${index}`,
                            index
                          )
                        }
                        checked={
                          !showAdditionalPlanInputs[`sp-shrink-wrap-${index}`]
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`decideShrinkWrap-no-${index}`}
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {showAdditionalPlanInputs[`sp-shrink-wrap-${index}`] && (
                    <div
                      className={`form-group d-flex flex-column form-group dark-input sp-special-inputs ${
                        ((areFieldsEmpty &&
                          isEmpty(
                            shipmentAdditionalInputs[`sp-shrink-wrap-${index}`]
                              .amount
                          )) ||
                          packagingLimitsError[`sp-shrink-wrap-${index}`]) &&
                        "form-error"
                      }`}
                    >
                      <input
                        value={
                          (shipmentAdditionalInputs[`sp-shrink-wrap-${index}`]
                            .amount as string) || ""
                        }
                        id={`sp-shrink-wrap-${index}`}
                        placeholder="Amount"
                        autoComplete="none"
                        onChange={(e) => handleOnChange(e, index)}
                      />
                      {packagingLimitsError[`sp-shrink-wrap-${index}`] && (
                        <span className="error">Range 1 - 8</span>
                      )}
                    </div>
                  )}
                </div>

                <div>
                  <div className="d-flex flex-column">
                    <h5>Special packaging?</h5>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`decideSpecialPackaging-yes-${index}`}
                        name={`sp-special-packaging-${index}`}
                        onChange={() =>
                          handleRadioButtonsClick(
                            `sp-special-packaging-${index}`,
                            index
                          )
                        }
                        checked={
                          showAdditionalPlanInputs[
                            `sp-special-packaging-${index}`
                          ]
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`decideSpecialPackaging-yes-${index}`}
                      >
                        Yes
                      </label>
                    </div>

                    <div className="form-check radio-container">
                      <input
                        className="form-check-input"
                        type="radio"
                        autoComplete="none"
                        id={`decideSpecialPackaging-no-${index}`}
                        name={`sp-special-packaging-${index}`}
                        value="No"
                        onChange={() =>
                          handleRadioButtonsClick(
                            `sp-special-packaging-${index}`,
                            index
                          )
                        }
                        checked={
                          !showAdditionalPlanInputs[
                            `sp-special-packaging-${index}`
                          ]
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`decideSpecialPackaging-no-${index}`}
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {showAdditionalPlanInputs[
                    `sp-special-packaging-${index}`
                  ] && (
                    <div
                      className={`form-group d-flex flex-column form-group dark-input sp-special-inputs ${
                        ((areFieldsEmpty &&
                          isEmpty(
                            shipmentAdditionalInputs[
                              `sp-special-packaging-${index}`
                            ].amount
                          )) ||
                          packagingLimitsError[
                            `sp-special-packaging-${index}`
                          ]) &&
                        "form-error"
                      }`}
                    >
                      <input
                        value={
                          (shipmentAdditionalInputs[
                            `sp-special-packaging-${index}`
                          ].amount as string) || ""
                        }
                        id={`sp-special-packaging-${index}`}
                        placeholder="Amount"
                        autoComplete="none"
                        onChange={(e) => handleOnChange(e, index)}
                      />
                      {packagingLimitsError[
                        `sp-special-packaging-${index}`
                      ] && <span className="error">Range 1 - 8</span>}
                    </div>
                  )}
                </div>
              </div>
              <div className="sp-comments mt-3">
                <textarea
                  id={`sp-comments-${index}`}
                  onChange={(e) => handleOnChange(e, index)}
                  value={
                    shipmentAdditionalInputs[`sp-comments-${index}`] as string
                  }
                />

                <h5>Comments</h5>
              </div>
            </div>
          </div>
          {showQuantityWarning[index] && (
            <div className="bundling-warning-message text-center">
              <p>
                Your bundle quantity is not optimal for your total items
                quantity. Some bundles may not be completed.
                <br /> If you want to proceed, please instruct our team in the
                comments section.
              </p>
            </div>
          )}

          <div className="d-flex flex-wrap justify-content-center mt-3 mb-3">
            <div
              className={`${shouldGreyOutPaymentBtn && "sp-greyed-out-button"}`}
            >
              <button
                type="button"
                className={`btn btn-success btn-generic me-3 ${
                  shipmentPlan.payment.paid && "disabled-button"
                }`}
                disabled={shipmentPlan.payment.paid}
                onClick={onPayShipmentPlanCb}
              >
                Pay (Total: ${getTotalPrice()})
                <br />
              </button>
            </div>
            <button
              type="button"
              className={`btn btn-success btn-generic me-3 mt-3 mt-sm-0 ${
                shipmentPlan.payment.paid && "disabled-button"
              }`}
              disabled={shipmentPlan.payment.paid}
              onClick={onUpdateShipmentPlanCb}
            >
              Update Shipment Plan
            </button>
            <button
              type="button"
              className={`btn btn-success btn-generic me-3 mt-3 mt-sm-0 ${
                shipmentPlan.payment.paid && "disabled-button"
              }`}
              disabled={shipmentPlan.payment.paid}
              onClick={onFindWarehouseRedirectCb}
            >
              Find my warehouse
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipmentPlanProduct;
