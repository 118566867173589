import { useContext, useEffect, useRef, useState } from "react";

import {
  Footer,
  FullScreenLoading,
  Loading,
  Modal,
  PageTitle,
  ProfitableProductsList,
  SidebarMenu,
} from "components";

import "./styles.scss";
import {
  QUERY_KEYS,
  UserContext,
  localStorageNames,
  pageRoutes,
  useDebounce,
} from "utils";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  changeDefaultRequest,
  deleteFolderRequest,
  deleteItemFromFolderRequest,
  editFolderRequest,
  getFoldersRequest,
  postAddFolderRequest,
  postShipmentPlan,
  updateSupplierProductDetailsRequest,
  updateSupplierRequest,
} from "./api";
import {
  IChangeDefaultFolderResponse,
  IDeleteFolderResponse,
  IDeleteItemFromFolderResponse,
  IEditFolderResponse,
  IFolder,
  ILocalStorageShipmentItems,
  IPostAddFolderResponse,
  IPostShipmentPlanProducts,
  IPostShipmentPlanRequest,
  IUpdateSupplierResponse,
} from "./interfaces";
import { toast } from "react-toastify";
import {
  IDeleteItemCB,
  IItemInFolder,
  IShipmentPlanAdditionalInfo,
} from "components/ProfitableProductsList/interfaces";
import { ISupplier } from "pages/suppliers/interfaces";
import ShipmentPlanModalList from "components/ShipmentPlanModalList";
import { IContextUser } from "common/interfaces";
import { isEmpty } from "lodash";

const ProfitableProducts = () => {
  const user: IContextUser = useContext(UserContext)!;
  const editFolderNameRef = useRef(null);
  const { email } = user;

  const [editedFolderDetails, setEditedFolderDetails] = useState({
    folderName: "",
    folderId: "",
  });
  const [showFolderEdit, setShowFolderEdit] = useState("");
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [createFolderName, setCreateFolderName] = useState("");
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
  const [currentFolderForDeletion, setCurrentFolderForDeletion] = useState("");
  const [createFolderError, setCreateFolderError] = useState(false);
  const [editFolderError, setEditFolderError] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [showDefaultFolderModal, setShowDefaultFolderModal] = useState(false);
  const [showCreateShipmentPlanModal, setShowCreateShipmentPlanModal] =
    useState(false);
  const [defaultFolderId, setDefaultFolderId] = useState("");
  const [shipmentPlanItems, setShipmentPlanItems] = useState<IItemInFolder[]>(
    []
  );
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [shipmentPlanErrors, setShipmentPlanErrors] = useState<string>("");
  const [searchASINCodeTerm, setSearchASINCodeTerm] = useState<string>("");
  const [profitableProducts, setProfitableProducts] = useState<IItemInFolder[]>(
    []
  );
  const [ASINCodeResults, setASINCodeResults] = useState<IItemInFolder[]>([]);
  const debounceASINCode = useDebounce(searchASINCodeTerm, 500);

  const shipmentTitleRef = useRef<HTMLInputElement>(null);
  const orderNoRef = useRef<HTMLInputElement>(null);
  const isShipmentPlanDisabled = shipmentPlanItems.length > 0 ? false : true;

  const { data, isLoading: isLoadingGetFolders } = useQuery(
    QUERY_KEYS.GET_FOLDERS,
    () => getFoldersRequest(email),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [folders, setFolders] = useState<IFolder[]>([]);

  const queryClient = useQueryClient();

  const generateColor = () => {
    return `#${Math.random().toString(16).substr(-6)}`;
  };

  const { mutate: postAddFolder, isLoading: isLoadingPostAddFolder } =
    useMutation(postAddFolderRequest, {
      onSuccess: (data: IPostAddFolderResponse) => {
        if (data.status === "success") {
          toast.success("Successfully added folder.");
          setFolders(data.response.folders);
        } else {
          toast.error("There was an error creating your folder.");
        }
        return;
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error creating your folder."
        );
        return;
      },
    });

  const { mutate: deleteFolder, isLoading: isLoadingDeleteFolder } =
    useMutation(deleteFolderRequest, {
      onSuccess: (data: IDeleteFolderResponse) => {
        if (data.status === "success") {
          toast.success("Successfully deleted folder.");
          setFolders(data.response.folders);
          setSelectedFolderId(data?.response?.folders[0]._id);
        } else {
          toast.error("There was an error deleting your folder.");
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error deleting your folder."
        );
        return;
      },
    });

  const { mutate: editFolder, isLoading: isLoadingEditFolder } = useMutation(
    editFolderRequest,
    {
      onSuccess: (data: IEditFolderResponse) => {
        if (data.status) {
          toast.success("Successfully renamed your folder.");
          setFolders(data.response.folders);
          setShowFolderEdit("");
          setEditedFolderDetails({
            folderId: "",
            folderName: "",
          });
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error editing your folder name."
        );
        return;
      },
    }
  );

  const {
    mutate: changeDefaultFolder,
    isLoading: isLoadingChangeDefaultFolder,
  } = useMutation(changeDefaultRequest, {
    onSuccess: (data: IChangeDefaultFolderResponse) => {
      if (data.status) {
        toast.success("Successfully changed default folder.");
        setFolders(data.response.folders);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error changing the default folder."
      );
      return;
    },
  });

  const {
    mutate: deleteItemFromFolder,
    isLoading: isLoadingDeleteItemFromFolder,
  } = useMutation(deleteItemFromFolderRequest, {
    onSuccess: (data: IDeleteItemFromFolderResponse) => {
      if (data.status) {
        toast.success("Successfully deleted item from folder.");
        setFolders(data.response.folders);
      }
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error editing your folder name."
      );
      return;
    },
  });

  const { mutate: updateSupplierProductDetails } = useMutation(
    updateSupplierProductDetailsRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_FOLDERS });
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error updating the supplier."
        );
        return;
      },
    }
  );

  const { mutate: updateSupplierForProduct } = useMutation(
    updateSupplierRequest,
    {
      onSuccess: (supplierSuccessData: IUpdateSupplierResponse) => {
        if (supplierSuccessData.status) {
          toast.success("Successfully supplier for selected item.");
          setFolders(supplierSuccessData?.response?.folders);
        }
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.message ??
            "There was an error updating the supplier."
        );
        return;
      },
    }
  );

  const {
    mutate: postShipmentPlanMutation,
    isLoading: isLoadingPostShipmentPlan,
  } = useMutation(postShipmentPlan, {
    onSuccess: (shipmentPlanSuccessData) => {
      const planId = shipmentPlanSuccessData.response.planId;
      const updatedFolders = folders.map((folder: IFolder) => {
        folder.folderItems.forEach((folderItem) => {
          if (folderItem.isChecked) {
            folderItem.isChecked = false;
          }
        });
        return folder;
      });

      setFolders(updatedFolders);
      setShipmentPlanItems([]);
      setShowCreateShipmentPlanModal(false);
      toast.success(shipmentPlanSuccessData.message);

      window.location.href = `${pageRoutes.SHIPMENT_PLAN_ID}?id=${planId}`;
    },
    onError: (error: any) => {
      toast.error(
        error.response.data.message ??
          "There was an error adding the shipment plan."
      );
    },
  });

  const onFolderNameChange = (id: string, createFolderName: string) => {
    setEditFolderError(false);

    setEditedFolderDetails({
      folderId: id,
      folderName: createFolderName,
    });
  };

  const onFolderEditSubmit = () => {
    if (!editedFolderDetails.folderName) {
      setEditFolderError(true);
    } else {
      setShowFolderEdit("");
      editFolder({
        email,
        folderId: editedFolderDetails.folderId,
        folderName: editedFolderDetails.folderName,
      });
    }
  };

  const folderInput = () => {
    return (
      <div className="dark-input my-3">
        <input
          type="text"
          className={`add-new-folder-input simple-input mb-2 ${
            createFolderError && "error-input"
          }`}
          onChange={(e) => {
            setCreateFolderError(false);
            setCreateFolderName(e.target.value);
          }}
          value={createFolderName}
          placeholder="Name of the folder (category)"
        />
        {createFolderError && (
          <p className="error">Name of the folder cannot be empty.</p>
        )}
      </div>
    );
  };

  const onCreateFolderConfirmClick = () => {
    if (!createFolderName) {
      setCreateFolderError(true);
    } else {
      setShowNewFolderModal(false);
      postAddFolder({
        email,
        folderName: createFolderName,
        folderColor: generateColor(),
      });
    }
  };

  const onClickDeleteFolder = (folderId: string) => {
    setShowDeleteFolderModal(true);
    setCurrentFolderForDeletion(folderId);
  };

  const onModalConfirmDeleteFolderClick = () => {
    deleteFolder({ email, folderId: currentFolderForDeletion });
    setShowDeleteFolderModal(false);
  };

  const onDeleteItemClickCb = ({ folderId, folderItemId }: IDeleteItemCB) => {
    deleteItemFromFolder({ email, folderId, folderItemId });
  };

  const onChangeDefaultFolderClick = (folderId: string) => {
    setDefaultFolderId(folderId);
    setShowDefaultFolderModal(true);
  };

  const onModalSetNewDefaultFolderClick = () => {
    setShowDefaultFolderModal(false);
    changeDefaultFolder({
      email,
      folderId: defaultFolderId,
      folderSelected: true,
    });
  };

  const onSelectProductsForShipmentPlanClickCb = (
    selectedItem: IItemInFolder
  ) => {
    const itemIndex = shipmentPlanItems.findIndex(
      (item: IItemInFolder) => item._id === selectedItem._id
    );
    const isTheSameASIN =
      shipmentPlanItems.length > 0 &&
      shipmentPlanItems.some(
        (item: IItemInFolder) =>
          item.asin === selectedItem.asin && item._id !== selectedItem._id
      );
    const isTheSameSupplier =
      shipmentPlanItems.length > 0 &&
      shipmentPlanItems.some(
        (item: IItemInFolder) =>
          item.supplier.supplierName !== selectedItem.supplier.supplierName
      );

    if (isTheSameSupplier) {
      toast.error("Please select products with the same supplier.");
    } else if (isTheSameASIN) {
      toast.error("A product with same ASIN is already selected.");
    } else if (!isEmpty(selectedItem.supplier.supplierName)) {
      const updatedFolders = folders.map((folder: IFolder) => {
        folder.folderItems.forEach((folderItem) => {
          if (folderItem._id === selectedItem._id) {
            folderItem.isChecked = !folderItem.isChecked;
            folderItem.unitsPerBox = "";
            folderItem.width = "";
            folderItem.height = "";
            folderItem.length = "";
          }
        });

        return folder;
      });

      setFolders(updatedFolders);

      if (itemIndex !== -1) {
        const updatedItems = [...shipmentPlanItems];
        updatedItems.splice(itemIndex, 1);
        setShipmentPlanItems(updatedItems);
        const savedSelectedItems = {
          selectedFolderId,
          updatedItems,
        };
        localStorage.setItem(
          localStorageNames.selectedShipmentPlans,
          JSON.stringify(savedSelectedItems)
        );
      } else {
        const updatedItems = [...shipmentPlanItems, selectedItem];
        const savedSelectedItems = {
          selectedFolderId,
          updatedItems,
        };
        localStorage.setItem(
          localStorageNames.selectedShipmentPlans,
          JSON.stringify(savedSelectedItems)
        );
        setShipmentPlanItems(updatedItems);
      }
    } else {
      toast.error(
        "Please select a supplier first, before adding the product to shipment plan."
      );
    }
  };

  const onDeleteSelectedProductCb = (id: string) => {
    const updatedFolders = folders.map((folder: IFolder) => {
      folder.folderItems.forEach((folderItem) => {
        if (folderItem._id === id) {
          folderItem.isChecked = !folderItem.isChecked;
        }
      });
      return folder;
    });
    setFolders(updatedFolders);
    setShipmentPlanItems(shipmentPlanItems.filter((item) => item._id !== id));
  };

  const handleValidation = () => {
    if (isEmpty(shipmentTitleRef.current?.value)) {
      setShipmentPlanErrors("shipment-title");
    }
  };

  const handleInputChange = (
    productIndex: number | undefined = 0,
    fieldName: keyof IShipmentPlanAdditionalInfo | undefined,
    value: string
  ) => {
    if (
      shipmentPlanErrors.includes("shipment-title") &&
      !isEmpty(shipmentTitleRef.current?.value)
    ) {
      setShipmentPlanErrors("");
    }

    if (fieldName) {
      const updatedProducts = [...shipmentPlanItems];
      updatedProducts[productIndex][fieldName] = value;
      setShipmentPlanItems(updatedProducts);
    }
  };

  const onSubmitProductsForShipmentPlan = () => {
    if (isEmpty(shipmentTitleRef.current?.value)) {
      setShipmentPlanErrors("shipment-title");
      handleValidation();
    } else {
      const products: IPostShipmentPlanProducts[] = shipmentPlanItems.map(
        ({
          title,
          asin,
          date,
          price,
          _id,
          supplier,
          imageUrl,
          unitsPerBox,
          width,
          height,
          length,
        }) => ({
          asin: asin,
          title: title,
          unitsPerBox,
          dateAdded: date,
          boxWidth: width,
          boxHeight: height,
          boxLength: length,
          amazonPrice: price,
          imageUrl,
          supplier: {
            _id: _id,
            onlineSupplier: supplier.onlineSupplier,
            supplierName: supplier.supplierName,
            supplierAddress: supplier.supplierAddress,
            supplierLink: supplier.supplierLink,
            contactPerson: supplier.contactPerson,
          },
        })
      );
      const shipmentTitle = shipmentTitleRef.current!!.value;
      const orderNo = orderNoRef.current?.value || "";
      const payload: IPostShipmentPlanRequest = {
        email,
        shipmentTitle,
        orderNo,
        orderDate: startDate!!.toISOString(),
        products,
      };

      setShipmentPlanErrors("");
      postShipmentPlanMutation(payload);
    }
  };

  const onSupplierChange = (
    supplier: ISupplier | undefined,
    folderId: string,
    productId: string,
    currentItem: IItemInFolder
  ) => {
    if (supplier) {
      updateSupplierForProduct({ supplier, folderId, productId });
      updateSupplierProductDetails({
        email,
        asin: currentItem.asin,
        title: currentItem.title,
        imageUrl: currentItem.imageUrl,
        price: currentItem.price,
        folderId,
        supplier,
      });
    } else {
      toast.error("There was an error submiting your supplier update");
    }
  };

  const searchASINCode = (item: string) => {
    setSearchASINCodeTerm(item);
  };

  useEffect(() => {
    if (!isEmpty(debounceASINCode)) {
      setASINCodeResults(
        profitableProducts.filter((planItem) =>
          planItem.asin.toUpperCase().includes(debounceASINCode.toUpperCase())
        )
      );
    } else setASINCodeResults([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceASINCode]);

  useEffect(() => {
    if (data?.response?.folders) {
      data.response?.folders.forEach((item) =>
        item.folderItems.forEach((folderItem) => (folderItem.isChecked = false))
      );
      const allProfitableProducts: IItemInFolder[] = [];
      data.response?.folders.forEach((item) =>
        item.folderItems.forEach((folderItem) =>
          allProfitableProducts.push(folderItem)
        )
      );

      setProfitableProducts(allProfitableProducts);

      const selectedShipmentPlans: ILocalStorageShipmentItems = JSON.parse(
        localStorage.getItem(localStorageNames.selectedShipmentPlans) || "{}"
      );

      if (!isEmpty(selectedShipmentPlans)) {
        const updatedData: IFolder[] = [];
        const updatedShipmentPlanItems: IItemInFolder[] = [];
        data?.response?.folders.forEach((folder) => {
          folder.folderItems.forEach((item) => {
            selectedShipmentPlans.updatedItems?.forEach((selectedItem) => {
              if (selectedItem._id === item._id && item.supplier.supplierName) {
                item.isChecked = selectedItem.isChecked;
                updatedShipmentPlanItems.push(item);
              }
            });
          });
          updatedData.push(folder);
        });

        setShipmentPlanItems(updatedShipmentPlanItems);
        setFolders(updatedData);
        setSelectedFolderId(selectedShipmentPlans.selectedFolderId);
      } else {
        setFolders(data?.response?.folders);
        setSelectedFolderId(data?.response?.folders[0]._id);
      }
    }
  }, [data]);

  useEffect(() => {
    if (isEmpty(shipmentPlanItems)) {
      setShowCreateShipmentPlanModal(false);
    }
  }, [shipmentPlanItems]);

  useEffect(() => {
    const selectedShipmentPlans: ILocalStorageShipmentItems = JSON.parse(
      localStorage.getItem(localStorageNames.selectedShipmentPlans) || "{}"
    );
    selectedShipmentPlans.selectedFolderId = selectedFolderId;
    localStorage.setItem(
      localStorageNames.selectedShipmentPlans,
      JSON.stringify(selectedShipmentPlans)
    );
  }, [selectedFolderId]);

  useEffect(() => {
    localStorage.removeItem(localStorageNames.selectedShipmentPlans);
  }, []);

  return (
    <>
      <div className="content">
        {isLoadingPostShipmentPlan && <FullScreenLoading />}

        <div className="container-fluid profitableProducts">
          <SidebarMenu
            active="profitable-products"
            setSearchTerm={searchASINCode}
          />
          <PageTitle pageTitle="Profitable Product List" />
          {showNewFolderModal && (
            <Modal
              okButtonText="Create"
              modalTitle="Add new folder"
              modalTextContent="Please enter the name of the folder that you want to create"
              children={folderInput()}
              okModalClick={onCreateFolderConfirmClick}
              cancelModalClick={() => setShowNewFolderModal(false)}
            />
          )}
          {showDeleteFolderModal && (
            <Modal
              okButtonText="Delete"
              modalTitle="Are you sure you want to delete this folder?"
              modalTextContent="Please be advised that selecting 'Delete' will result in the permanent removal of the folder, along with all of its contents."
              okModalClick={onModalConfirmDeleteFolderClick}
              cancelModalClick={() => setShowDeleteFolderModal(false)}
            />
          )}
          {showDefaultFolderModal && (
            <Modal
              okButtonText="Set new default"
              modalTitle="Are you sure you want to change the default folder?"
              modalTextContent="If you change the default folder, whenever you will add a new profitable product from the chrome extension, 
              it will go directly to this default folder. Of course this will change once you select a new folder."
              okModalClick={onModalSetNewDefaultFolderClick}
              cancelModalClick={() => setShowDefaultFolderModal(false)}
            />
          )}
          {showCreateShipmentPlanModal && (
            <Modal
              modalDialogStyle={{ maxWidth: "800px" }}
              okButtonText="Create"
              okModalClick={onSubmitProductsForShipmentPlan}
              modalTitle="Confirm Shipment Plan"
              children={
                <ShipmentPlanModalList
                  shipmentPlanItems={shipmentPlanItems}
                  handleDelete={onDeleteSelectedProductCb}
                  shipmentTitleRef={shipmentTitleRef}
                  shipmentPlanErrors={shipmentPlanErrors}
                  handleInputChange={handleInputChange}
                  orderNoRef={orderNoRef}
                  startDate={startDate}
                  setStartDateCb={setStartDate}
                />
              }
              cancelModalClick={() => {
                setShowCreateShipmentPlanModal(false);
                setShipmentPlanErrors("");
              }}
            />
          )}
          <div className="row">
            {isLoadingGetFolders ? (
              <Loading />
            ) : (
              <>
                {isEmpty(ASINCodeResults) && (
                  <div className="col-md-3 col-lg-3 col-xl-2">
                    <div className="mb-3">
                      <div
                        className="add-new-folder-container mb-3"
                        onClick={() => {
                          setCreateFolderName("");
                          setShowNewFolderModal(!showNewFolderModal);
                        }}
                      >
                        <div className="add-new-folder">
                          <span>+</span>
                          <p>New Folder</p>
                        </div>
                      </div>
                    </div>

                    {isEmpty(ASINCodeResults) && (
                      <div className="card mb-3">
                        <div className="card-body max-h-400px">
                          <div className="folders-list">
                            {isLoadingPostAddFolder ||
                            isLoadingDeleteFolder ||
                            isLoadingChangeDefaultFolder ? (
                              <Loading />
                            ) : folders.length === 0 ? (
                              <div className="no-folders text-center">
                                <h3>Start Organizing</h3>
                                <br />
                                <p>
                                  Tap the 'Add' Button to Create a Folder and
                                  Begin Organizing Your Products into
                                  Categories!
                                </p>
                              </div>
                            ) : (
                              folders.map((folder: IFolder) => (
                                <div
                                  className={`folder ps-3 ${
                                    selectedFolderId === folder._id &&
                                    "folder-active"
                                  }`}
                                  id="folder1"
                                  key={folder._id}
                                  onClick={() =>
                                    setSelectedFolderId(folder._id)
                                  }
                                >
                                  <span
                                    className="folder-delete"
                                    onClick={() =>
                                      onClickDeleteFolder(folder._id)
                                    }
                                  >
                                    x
                                  </span>
                                  <input
                                    type="radio"
                                    name="folder-selected"
                                    className="folder-selected ms-3"
                                    checked={folder.folderSelected}
                                    onChange={() =>
                                      onChangeDefaultFolderClick(folder._id)
                                    }
                                  />
                                  <i
                                    className="fa fa-folder"
                                    aria-hidden="true"
                                    style={{ color: folder.folderColor }}
                                  ></i>

                                  {showFolderEdit !== folder.folderName ? (
                                    <p
                                      className="folder-name text-center"
                                      onDoubleClick={() => {
                                        setEditFolderError(false);
                                        setShowFolderEdit(folder.folderName);
                                        setEditedFolderDetails({
                                          folderName: folder.folderName,
                                          folderId: folder._id,
                                        });
                                      }}
                                    >
                                      {folder.folderName}
                                    </p>
                                  ) : (
                                    <div className="dark-input">
                                      {isLoadingEditFolder && (
                                        <div className="input-loading-wrapper">
                                          <Loading />
                                        </div>
                                      )}
                                      <input
                                        type="text"
                                        className={`folder-edit simple-input ${
                                          editFolderError && "input-error"
                                        }`}
                                        value={editedFolderDetails.folderName}
                                        onChange={(e) => {
                                          e.stopPropagation();
                                          onFolderNameChange(
                                            folder._id,
                                            e.target.value
                                          );
                                        }}
                                        ref={editFolderNameRef}
                                      />
                                      {editFolderError && (
                                        <p className="error">Enter a value</p>
                                      )}
                                      <i
                                        className="fa fa-check submit-folder-name-edit"
                                        onClick={onFolderEditSubmit}
                                      />
                                    </div>
                                  )}
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={
                    isEmpty(ASINCodeResults)
                      ? "col-md-9 col-lg-9 col-xl-10"
                      : ""
                  }
                >
                  {folders.find(
                    (folder: IFolder) =>
                      JSON.stringify(folder._id) ===
                      JSON.stringify(selectedFolderId)
                  )?.folderItems.length === 0 ? (
                    <div className="no-items text-center">
                      <h3>This Folder is Empty!</h3>
                      <p>Add Products To This List</p>
                      <span className="no-items-icon-wrapper">
                        <i className="fas fa-hand-holding-usd"></i>
                        {/* <img src={emptyFolderPng} alt="" /> */}
                      </span>
                    </div>
                  ) : isLoadingDeleteItemFromFolder ? (
                    <div className="input-loading-wrapper">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <span>
                        <button
                          type="button"
                          className={`btn btn-success btn-generic mb-3 ${
                            isShipmentPlanDisabled && "disabled-button"
                          }`}
                          onClick={() => setShowCreateShipmentPlanModal(true)}
                          disabled={isShipmentPlanDisabled}
                        >
                          Create shipment plan
                        </button>
                      </span>

                      <ProfitableProductsList
                        itemsInFolder={
                          !isEmpty(ASINCodeResults)
                            ? ASINCodeResults
                            : folders.find(
                                (folder: IFolder) =>
                                  JSON.stringify(folder._id) ===
                                  JSON.stringify(selectedFolderId)
                              )?.folderItems
                        }
                        onDeleteItemClickCb={onDeleteItemClickCb}
                        onSelectProductsForShipmentPlanClickCb={
                          onSelectProductsForShipmentPlanClickCb
                        }
                        onSupplierChange={(
                          supplier,
                          folderId,
                          productId,
                          currentItem
                        ) =>
                          onSupplierChange(
                            supplier,
                            folderId,
                            productId,
                            currentItem
                          )
                        }
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ProfitableProducts;
