import axios from "axios";
import Cookies from "js-cookie";

import { cookiesNames, localStorageNames, pageRoutes } from "utils";

export const configureAxiosInterceptors = () => {
  const token = Cookies.get(cookiesNames.token) || "";

  axios.interceptors.request.use(
    async (config: any) => {
      let serverCallUrl = new URL(config.url);

      if (
        serverCallUrl.pathname.includes("/login") ||
        serverCallUrl.pathname.includes("/register")
      )
        return config;

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      if (status === 401) {
        Cookies.remove(cookiesNames.token);
        localStorage.removeItem(localStorageNames.userObj);
        window.location.href = `${pageRoutes.LOGIN}?user="unauthorized"`;
      }
      return Promise.reject(error);
    }
  );
};
