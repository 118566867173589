import axios from "axios";
import { apiRoutes } from "utils";
import {
  IForgotFormType,
  IForgotResponse,
  ILoginFormType,
  ILoginResponse,
} from "../interfaces";

export const postLoginRequest = async ({ email, password }: ILoginFormType) => {
  const { data } = await axios.post<ILoginResponse>(apiRoutes.LOGIN, {
    email,
    password,
  });
  return data;
};

export const getForgotPassword = async ({ forgotEmail }: IForgotFormType) => {
  const { data } = await axios.get<IForgotResponse>(
    `${apiRoutes.FORGOT_PASSWORD}?email=${forgotEmail}`
  );
  return data;
};
