import {
  Footer,
  Loading,
  Modal,
  PdfContent,
  SidebarMenu,
  Table,
} from "components";
import { useQuery } from "react-query";
import { QUERY_KEYS, UserContext } from "utils";
import { useContext, useEffect, useState } from "react";
import { getProfitableProductDetails } from "./api";
import { useSearchParams } from "react-router-dom";
import {
  IGetProfitableProductDetailsQueryError,
  IGetProfitableProductDetailsResponse,
  IProductDetails,
} from "./interfaces";
import { toast } from "react-toastify";
import { PDFViewer } from "@react-pdf/renderer";

import "./styles.scss";
import dayjs from "dayjs";
import { IContextUser } from "common/interfaces";

const ProfitableProductsDetails = () => {
  let user: IContextUser = useContext(UserContext)!;
  console.log(user);
  const { email } = user;
  const [searchParams] = useSearchParams();
  const [productDetails, setProductDetails] = useState<any>([]);
  const folderId = searchParams.get("folderId");
  const asin = searchParams.get("asin");
  const [pdfPreviewModal, setShowPdfPreviewModal] = useState(false);

  const {
    data,
    error,
    isLoading: isLoadingGetProfitableProductDetails,
    refetch: getProfProductsQuery,
  } = useQuery<
    IGetProfitableProductDetailsResponse,
    IGetProfitableProductDetailsQueryError
  >(
    QUERY_KEYS.GET_PROFITABLE_PRODUCT_DETAILS,
    () => getProfitableProductDetails({ email, folderId, asin }),
    {
      enabled: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (email && folderId && asin) {
      getProfProductsQuery();
    }
  }, [email, folderId, asin, getProfProductsQuery]);

  useEffect(() => {
    toast.error(error?.response.data.message);
  }, [error]);

  useEffect(() => {
    if (data) {
      setProductDetails(data?.response.productDetails);
    }
  }, [data]);

  return (
    <>
      <div className="content profitable-products-details">
        {productDetails.length > 0 && pdfPreviewModal && (
          <Modal
            okButtonText="Create"
            modalTitle="Add new folder"
            modalTextContent="Please enter the name of the folder that you want to create"
            children={
              <PDFViewer style={{ width: "100%", height: "65vh" }}>
                <PdfContent productDetails={productDetails} />
              </PDFViewer>
            }
            cancelModalClick={() => setShowPdfPreviewModal(false)}
          />
        )}
        <div className="container-fluid">
          <SidebarMenu active="profitable-products" sidebarOnly={true} />
        </div>
        {isLoadingGetProfitableProductDetails && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center loading-wrapper">
              <Loading />
            </div>
          </div>
        )}

        {data?.response && (
          <>
            <div className="row ps-4">
              {/* <div className="col-12">{JSON.stringify(data?.response)}</div> */}
              {/* <div className="col-12">{JSON.stringify(error?.response.data.message)}</div> */}
              {productDetails.length > 0 && (
                <div className="col-12 top-chart-part">
                  <h4 className="mb-5">{productDetails[0]?.title}</h4>
                  <div className="row">
                    <div className="col-3">
                      <div className="d-flex justify-content-center">
                        <img src={productDetails[0]?.imageUrl} alt="" />
                      </div>
                      <button
                        className="btn btn-success mt-3"
                        onClick={() => setShowPdfPreviewModal(true)}
                      >
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>{" "}
                        Export PDF
                      </button>
                    </div>
                    <div className="col-9">
                      <iframe
                        width="100%"
                        height="437px"
                        src={`https://www.unielogics.com/charts2?asin=${productDetails[0]?.asin}`}
                        title="asinmice-charts"
                      ></iframe>
                    </div>
                  </div>
                  {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
                    <PdfContent productDetails={productDetails} />
                  </PDFViewer> */}
                </div>
              )}
            </div>
            <div className="row pb-5">
              <div className="col-6">
                {productDetails.length > 0 && (
                  <div className="row calculator-section">
                    <div className="col-6">
                      <ul>
                        <h3 className="my-3 text-decoration-underline">
                          Calculator
                        </h3>
                        <li>
                          <span className="title-text">Price: </span>$
                          {productDetails[0].price}
                        </li>
                        <li>
                          <span className="title-text">Amazon fees: </span>$
                          {productDetails[0].amazonFees}
                        </li>
                        <li>
                          <span className="title-text">Pick & Pack: </span>$
                          {productDetails[0].pickPack}
                        </li>
                        <li>
                          <span className="title-text">Total Fees: </span>
                          {productDetails[0].totalFees}
                        </li>
                        <li>
                          <span className="title-text">Break Even: </span>
                          {productDetails[0].breakEven}
                        </li>
                        <li>
                          <span className="title-text">Cost Per Item: </span>
                          {productDetails[0].costPerItem}
                        </li>
                        <li>
                          <span className="title-text">Misc Expenses: </span>
                          {productDetails[0].miscExpenses}
                        </li>
                        <li>
                          <span className="title-text">
                            Total Cost Per Sale:{" "}
                          </span>
                          {productDetails[0].totalCostPerSale}
                        </li>
                        <li>
                          <span className="title-text">Net Profit: </span>
                          {productDetails[0].netProfit}
                        </li>
                        <li>
                          <span className="title-text">Units: </span>
                          {productDetails[0].units}
                        </li>
                        <li>
                          <span className="title-text">Total Profit: </span>
                          {productDetails[0].totalProfit}
                        </li>
                        <li>
                          <span className="title-text">Net Sales Margin: </span>
                          {productDetails[0].netSalesMargin}
                        </li>
                        <li>
                          <span className="title-text">Net ROI: </span>
                          {productDetails[0].netROI}
                        </li>
                      </ul>
                      <ul>
                        <h3 className="my-3 text-decoration-underline">
                          Supplier Data
                        </h3>
                        <li>
                          <span className="title-text">Supplier Details: </span>
                          {productDetails[0]?.supplier?._id ? (
                            <ul className="p-0">
                              <li>
                                <span className="text-decoration-underline">
                                  Supplier
                                </span>
                                <br />
                                <span className="title-text">Name: </span>
                                {productDetails[0]?.supplier.supplierName}
                              </li>
                              <li>
                                <span className="title-text">URL: </span>
                                <a
                                  href={
                                    productDetails[0]?.supplier.supplierLink
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Go To URL
                                </a>
                              </li>
                              <br />

                              <span className="text-decoration-underline">
                                Contact Person:
                              </span>
                              <li>
                                <span className="title-text">Name: </span>
                                {
                                  productDetails[0]?.supplier.contactPerson
                                    ?.name
                                }
                              </li>
                              <li>
                                <span className="title-text">Email: </span>
                                {
                                  productDetails[0]?.supplier.contactPerson
                                    ?.email
                                }
                              </li>
                              <li>
                                <span className="title-text">
                                  Phone Number:{" "}
                                </span>
                                {
                                  productDetails[0]?.supplier.contactPerson
                                    ?.phoneNumber
                                }
                              </li>
                              <li>
                                <span className="title-text">
                                  Extension Code:{" "}
                                </span>
                                {
                                  productDetails[0]?.supplier.contactPerson
                                    ?.extensionCode
                                }
                              </li>
                              <br />

                              <span className="text-decoration-underline">
                                Address:
                              </span>
                              <li>
                                <span className="title-text">City: </span>
                                {
                                  productDetails[0]?.supplier.supplierAddress
                                    ?.city
                                }
                              </li>
                              <li>
                                <span className="title-text">State: </span>
                                {
                                  productDetails[0]?.supplier.supplierAddress
                                    ?.state
                                }
                              </li>
                              <li>
                                <span className="title-text">Street: </span>
                                {
                                  productDetails[0]?.supplier.supplierAddress
                                    ?.street
                                }
                              </li>
                              <li>
                                <span className="title-text">Zip Code: </span>
                                {
                                  productDetails[0]?.supplier.supplierAddress
                                    ?.zipCode
                                }
                              </li>
                            </ul>
                          ) : (
                            "No supplier selected for this product"
                          )}
                        </li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul>
                        <h3 className="my-3 text-decoration-underline">
                          Details
                        </h3>
                        <li>
                          <span className="title-text">Asin: </span>
                          {productDetails[0].asin}
                        </li>
                        <li>
                          <span className="title-text">Buybox is FBA: </span>
                          {productDetails[0].buyboxIsFBA ? "Yes" : "No"}
                        </li>
                        <li>
                          <span className="title-text">FBA: </span>
                          {productDetails[0].offerCountFBA}
                        </li>
                        <li>
                          <span className="title-text">FBM: </span>
                          {productDetails[0].offerCountFBM}
                        </li>
                        <li>
                          <span className="title-text">Quantity Per Set: </span>
                          {productDetails[0].qtyPerSet}
                        </li>
                        <li>
                          <span className="title-text">Product group: </span>
                          {productDetails[0].productGroup}
                        </li>
                        <li>
                          <span className="title-text">Brand: </span>
                          {productDetails[0].brand}
                        </li>
                        <li>
                          <span className="title-text">Ian: </span>
                          {productDetails[0].ian}
                        </li>
                        <li>
                          <span className="title-text">
                            Last Price Change:{" "}
                          </span>
                          {dayjs(productDetails[0].lastPriceChange).format(
                            "MM/DD/YY"
                          )}
                        </li>
                        <li>
                          <span className="title-text">Weight: </span>
                          {productDetails[0].weight}
                        </li>
                        <li>
                          <span className="title-text">W x H x L: </span>
                          {productDetails[0].WxHxL}
                        </li>
                        <li>
                          <span className="title-text">Hazmat: </span>
                          {productDetails[0].isHazmat ? "YES" : "NO"}
                        </li>
                      </ul>
                      <ul>
                        <h3 className="my-3 text-decoration-underline">
                          Notes
                        </h3>
                        <li>
                          <span className="title-text">Note: </span>
                          {productDetails[0].note
                            ? productDetails[0].note
                            : "No notes for this product"}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-6">
                <h3 className="my-3 text-decoration-underline">
                  Buy Box Statistics
                </h3>
                {productDetails[0]?.buyboxStatistics.length > 0 ? (
                  <Table
                    maxHeightClass="max-h-250px"
                    tableData={productDetails[0]?.buyboxStatistics.map(
                      (item: IProductDetails) => {
                        // Create a copy of the item without the _id key
                        const { _id, ...rest } = item;
                        return rest;
                      }
                    )}
                    noMaxHeight={true}
                  />
                ) : (
                  <div className="d-flex title-text">
                    No BuyBox statistics for this product
                  </div>
                )}
                <h3 className="my-3 text-decoration-underline mt-3">
                  Variations View
                </h3>
                {productDetails[0]?.variations.length > 0 ? (
                  <Table
                    maxHeightClass="max-h-250px"
                    tableData={productDetails[0]?.variations.map(
                      (item: IProductDetails) => {
                        // Create a copy of the item without the _id key
                        const { _id, ...rest } = item;
                        return rest;
                      }
                    )}
                  />
                ) : (
                  <div className="d-flex title-text">
                    No Variations View for this product
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ProfitableProductsDetails;
