import dayjs from "dayjs";
import { Fragment, useContext, useEffect, useState } from "react";
import {
  QUERY_KEYS,
  UserContext,
  ellipsis,
  profitableProductsTableHeaders,
} from "utils";
import { IItemInFolder, IProfitableProductsList } from "./interfaces";

import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getSuppliersRequest } from "pages/suppliers/api";
import Loading from "components/Loading";
import AutocompleteDropdown from "components/AutocompleteDropdown";
import { ISupplier } from "pages/suppliers/interfaces";
import { IContextUser } from "common/interfaces";

const ProfitableProductsList = ({
  itemsInFolder,
  onDeleteItemClickCb,
  onSelectProductsForShipmentPlanClickCb,
  onSupplierChange,
}: IProfitableProductsList) => {
  const navigate = useNavigate();
  const user: IContextUser = useContext(UserContext)!;
  const { email } = user;
  const [suppliers, setSuppliers] = useState<any>([{ value: "", label: "" }]);
  const [originalSuppliers, setOriginalSuppliers] = useState<ISupplier[]>([]);
  const { data, isLoading: isLoadingGetSuppliers } = useQuery(
    QUERY_KEYS.GET_SUPPLIERS,
    () => getSuppliersRequest(email),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data?.response?.suppliers?.length) {
      setOriginalSuppliers(data?.response?.suppliers);
      const transformedSuppliers = data.response.suppliers.map((supplier) => ({
        value: supplier._id,
        label: supplier.supplierName,
      }));

      setSuppliers(transformedSuppliers);
    }
  }, [data]);

  const goToDetailsPage = ({
    folderId,
    asin,
  }: {
    folderId: string;
    asin: string;
  }) => {
    navigate(`/profitable-products/details?folderId=${folderId}&asin=${asin}`);
  };

  const onAutocompleteSelect = (
    option: any,
    productId: string,
    folderId: string,
    currentItem: IItemInFolder
  ) => {
    let selectedSupplier = {
      supplierName: "",
      supplierLink: "",
      onlineSupplier: "",
      supplierAddress: {
        city: "",
        state: "",
        zipCode: "",
        street: "",
        lat: 0,
        long: 0,
      },
      contactPerson: {
        email: "",
        name: "",
        phoneNumber: "",
        extensionCode: "",
      },
      _id: "no-supplier",
    };

    if (option.value !== "no-supplier") {
      selectedSupplier = originalSuppliers.find(
        (supplier) => supplier._id === option.value
      )!;
    }

    onSupplierChange(selectedSupplier, folderId, productId, currentItem);
  };

  return (
    <div className="table-responsive max-h-78vh profitable-products">
      <table className="table table-sm">
        <thead className="sticky-header">
          <tr>
            {profitableProductsTableHeaders.map((header) => (
              <th className="text-center" key={header.name}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {itemsInFolder &&
            itemsInFolder.length > 0 &&
            itemsInFolder.map((item) => (
              <Fragment key={item._id}>
                <tr>
                  <td
                    className="align-middle text-center white-space-nowrap"
                    style={{ width: "1%" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={() =>
                        onSelectProductsForShipmentPlanClickCb(item)
                      }
                      checked={item.isChecked || false}
                    />
                  </td>
                  <td
                    className="align-middle text-center white-space-nowrap"
                    style={{ width: "3%" }}
                  >
                    {item.date ? dayjs(item.date).format("MM/DD/YY") : ""}
                  </td>
                  <td
                    className="align-middle text-center white-space-nowrap"
                    style={{ width: "3%" }}
                  >
                    <a
                      href={`https://www.amazon.com/dp/${item.asin}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.asin}
                    </a>
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    <img src={item.imageUrl} alt="product-thumb" />
                  </td>
                  <td
                    className="align-middle"
                    style={{ width: "20%" }}
                    title={item.title}
                  >
                    {ellipsis(item.title, 100)}
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    ${item.price}
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    {item.isHazmat ? "YES" : "NO"}
                  </td>
                  <td className="align-middle text-center white-space-nowrap">
                    {(() => {
                      const currentDate = dayjs();
                      const itemDate = dayjs(item.date);

                      if (
                        itemDate.isAfter(
                          currentDate.subtract(3, "day"),
                          "day"
                        ) &&
                        itemDate.isBefore(currentDate)
                      ) {
                        return (
                          <span className="badge badge-success">
                            &lt; 3 days
                          </span>
                        );
                      } else if (
                        itemDate.isAfter(
                          currentDate.subtract(7, "day"),
                          "day"
                        ) &&
                        itemDate.isBefore(currentDate.subtract(3, "day"), "day")
                      ) {
                        return (
                          <span className="badge badge-warning">
                            3 - 7 days
                          </span>
                        );
                      } else {
                        return (
                          <span className="badge badge-danger">
                            &gt; 7 days
                          </span>
                        );
                      }
                    })()}
                  </td>
                  <td className="align-middle text-center white-space-no-wrap">
                    <span
                      className="icon-wrapper me-3"
                      onClick={() =>
                        goToDetailsPage({
                          folderId: item.folderId,
                          asin: item.asin,
                        })
                      }
                    >
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    </span>
                    <span
                      className="icon-wrapper me-3"
                      onClick={() =>
                        onDeleteItemClickCb({
                          folderId: item.folderId,
                          folderItemId: item._id,
                        })
                      }
                    >
                      <i
                        className="fa fa-trash-o text-danger"
                        aria-hidden="true"
                      ></i>
                    </span>
                  </td>
                  <td className="align-middle text-center white-space-no-wrap">
                    {isLoadingGetSuppliers && <Loading />}
                    {!isLoadingGetSuppliers && (
                      <div className="d-flex justify-content-center">
                        <AutocompleteDropdown
                          isDropdownDisabled={item.isChecked}
                          options={suppliers}
                          onSelectCb={(option: any) =>
                            onAutocompleteSelect(
                              option,
                              item._id,
                              item.folderId,
                              item
                            )
                          }
                          defaultOption={{
                            label: item.supplier?.supplierName || "",
                            value: item.supplier?._id || "",
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              </Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProfitableProductsList;
