import axios from "axios";
import { apiRoutes } from "utils";
import {
  IGetFoldersResponse,
  IAddFolder,
  IPostAddFolderResponse,
  IDeleteFolderResponse,
  IDeleteFolder,
  IEditFolder,
  IEditFolderResponse,
  IDeleteItemFromFolder,
  IDeleteItemFromFolderResponse,
  IChangeDefaultFolderResponse,
  IChangeDefaultFolder,
  IUpdateSupplier,
  IUpdateSupplierResponse,
  IUpdateSupplierProductDetailsRequest,
  IUpdateSupplierProductDetailsResponse,
  IPostShipmentPlanRequest,
  IPostShipmentPlanResponse,
} from "../interfaces";

export const getFoldersRequest = async (email: string) => {
  const { data } = await axios.get<IGetFoldersResponse>(
    `${apiRoutes.GET_FOLDERS}?email=${email}`
  );
  return data;
};

export const postAddFolderRequest = async ({
  email,
  folderName,
  folderColor,
}: IAddFolder) => {
  const { data } = await axios.post<IPostAddFolderResponse>(
    apiRoutes.POST_ADD_FOLDER,
    {
      email,
      folderName,
      folderColor,
    }
  );

  return data;
};

export const deleteFolderRequest = async ({
  folderId,
  email,
}: IDeleteFolder) => {
  const { data } = await axios.delete<IDeleteFolderResponse>(
    apiRoutes.DELETE_FOLDER,
    {
      data: { folderId, email },
    }
  );

  return data;
};

export const editFolderRequest = async ({
  email,
  folderId,
  folderName,
}: IEditFolder) => {
  const { data } = await axios.put<IEditFolderResponse>(
    apiRoutes.EDIT_FOLDER_NAME,
    { email, folderId, folderName }
  );

  return data;
};

export const deleteItemFromFolderRequest = async ({
  email,
  folderId,
  folderItemId,
}: IDeleteItemFromFolder) => {
  const { data } = await axios.delete<IDeleteItemFromFolderResponse>(
    apiRoutes.DELETE_ITEM_FROM_FOLDER,
    { data: { email, folderId, folderItemId } }
  );

  return data;
};

export const changeDefaultRequest = async ({
  email,
  folderId,
  folderSelected,
}: IChangeDefaultFolder) => {
  const { data } = await axios.put<IChangeDefaultFolderResponse>(
    apiRoutes.CHANGE_DEFAULT_FOLDER,
    { email, folderId, folderSelected }
  );

  return data;
};

export const updateSupplierRequest = async ({
  supplier,
  folderId,
  productId,
}: IUpdateSupplier) => {
  const { data } = await axios.put<IUpdateSupplierResponse>(
    apiRoutes.PUT_SUPPLIER,
    { folderId, productId, ...supplier }
  );

  return data;
};

export const updateSupplierProductDetailsRequest = async ({
  email,
  asin,
  title,
  imageUrl,
  price,
  folderId,
  supplier,
}: IUpdateSupplierProductDetailsRequest) => {
  const requestData = {
    note: "",
    supplierUrl: "",
    amazonFees: "",
    pickPack: "",
    totalFees: "",
    breakEven: "",
    costPerItem: "",
    miscExpenses: "",
    totalCostPerSale: "",
    netProfit: "",
    buyboxIsFBA: "",
    units: "",
    totalProfit: "",
    netSalesMargin: "",
    netROI: "",
    offerCountFBA: "",
    offerCountFBM: "",
    qtyPerSet: "",
    productGroup: "",
    brand: "",
    ian: "",
    lastPriceChange: "",
    weight: "",
    WxHxL: "",
    chartsURL: "",
    buyboxStatistics: [
      {
        seller: "",
        xPercentageWon: "",
        lastWon: "",
        stock: "",
        avgPrice: "",
        isFBA: "",
      },
    ],
    variations: [],
  };
  const { data } = await axios.post<IUpdateSupplierProductDetailsResponse>(
    apiRoutes.ADD_PROFITABLE_PRODUCT_DETAILS,
    { email, asin, title, imageUrl, price, folderId, supplier, ...requestData }
  );

  return data;
};

export const postShipmentPlan = async ({
  email,
  shipmentTitle,
  orderNo,
  orderDate,
  products,
}: IPostShipmentPlanRequest) => {
  const { data } = await axios.post<IPostShipmentPlanResponse>(
    apiRoutes.POST_ADD_SHIPMENT_PLAN,
    {
      email,
      shipmentTitle,
      orderNo,
      orderDate,
      products,
    }
  );

  return data;
};
